import React, { Component } from 'react';
import ActionMenu from '../utils/ActionMenu';
import { withRouter  } from 'react-router-dom';
import { roleId } from '../../constants/app';

class TableCities extends Component {
    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    
                    <th className="kt-datatable__cell table_citites_city">
                        <span>Город</span>
                    </th>
                    <th className="kt-datatable__cell table_citites_points">
                        <span>Филиалы</span>
                    </th>
                    <th className="kt-datatable__cell table_citites_time">
                        <span>Время работы</span>
                    </th>
                    <th className="kt-datatable__cell table_citites_delivery_time">
                        <span>Время доставки</span>
                    </th>
                    <th className="kt-datatable__cell table_citites_delivery_price">
                        <span>Стоимость доставки</span>
                    </th>
                    <th className="kt-datatable__cell table_citites_action">
                        <span>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/handbook/cities/edit/${+item.id}`)
    }

    renderFilial(items) {
        return items.map(item => <div key={item.id}>{item.street}, {item.house}{item.entrance ? `, подъезд ${item.entrance}` : ''}{item.room ? `, кв./оф. ${item.room}` : ''}</div>)
    }
    
    
    renderBody() {
        
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/handbook/cities/${+item.id}`) },
                            { access: roleId.admin, name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/cities/edit/${+item.id}`) },
                            { access: roleId.admin, name: 'Добавить филиал', icon: 'flaticon2-mail-1', action: () => this.props.history.push({ pathname: '/handbook/filials/add', state: { city: item.id } })},
                            { access: roleId.admin, name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.deleteItem(item) }
                        ].filter(item => !item.access || item.access === this.props.role);
                        return (
                            <tr
                            key={item.id}
                            className="kt-datatable__row"
                            onDoubleClick={this.handleDoubleClick(item)}                           
                            >
                            
                            <td className="kt-datatable__cell table_citites_city">
                                <span>
                                    {item.name}
                                </span>
                            </td>
                            <td className="kt-datatable__cell table_citites_points">
                                <span>
                                    { item.points.length ? this.renderFilial(item.points) : 'Нет филиалов' }
                                </span>
                            </td>
                            <td className="kt-datatable__cell table_citites_time">
                                <span>
                                    { item.deliveryWork }
                                </span>
                            </td>
                            <td className="kt-datatable__cell table_citites_delivery_time">
                                <span>
                                    { item.deliveryTime }     
                                </span>
                            </td>
                            <td className="kt-datatable__cell table_citites_delivery_price">
                                <span>
                                    { item.deliveryCost } руб.     
                                </span>
                            </td>
                            <td className="kt-datatable__cell table_citites_action">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    renderBodyMobile() {
        
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/handbook/cities/${+item.id}`) },
                            { access: roleId.admin, name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/cities/edit/${+item.id}`) },
                            { access: roleId.admin, name: 'Добавить филиал', icon: 'flaticon2-mail-1', action: () => this.props.history.push({ pathname: '/handbook/filials/add', state: { city: item.id } })},
                            { access: roleId.admin, name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.deleteItem(item) }
                        ].filter(item => !item.access || item.access === this.props.role);
                        return (
                            <tr 
                            key={item.id} 
                            className="kt-datatable__row"
                            onDoubleClick={this.handleDoubleClick(item)}
                            >
                            <div className='mobile_div_flex column padding10px'>
                                <div className='mobile_div_flex column_mobile430px'>
                                    <div style={window.innerWidth > 430 ? { width: '50%' } : { width: '100%' }}>
                                        <span>
                                           Город: <span style={{ color: '#000' }}>{item.name}</span>
                                        </span>
                                    </div>
                                    <div style={window.innerWidth > 430 ? { width: '50%' } : { width: '100%' }}>
                                        <span>
                                            Время работы: <span style={{ color: '#000' }}>{item.deliveryWork}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className='mobile_div_flex column_mobile430px'>
                                    <div style={window.innerWidth > 430 ? { width: '50%' } : { width: '100%' }}>
                                        <span>
                                           Время доставки: <span style={{ color: '#000' }}>{item.deliveryTime}</span>
                                        </span>
                                    </div>
                                    <div style={window.innerWidth > 430 ? { width: '50%' } : { width: '100%' }}>
                                        <span>
                                            Стоимость доставки: <span style={{ color: '#000' }}>{item.deliveryCost}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className='mobile_div_flex column_mobile430px'>
                                    <div style={window.innerWidth > 430 ? { width: '50%' } : { width: '100%' }}>
                                        <span>
                                            Филиалы:
                                        </span>
                                    </div>
                                    <div style={window.innerWidth > 430 ? { width: '50%' } : { width: '100%' }}>
                                        <span style={{ color: '#000' }}>
                                            { item.points.length ? this.renderFilial(item.points) : 'Нет филиалов' }
                                        </span>
                                    </div>
                                </div>
                                <div className='mobile_div_flex' style={{ width: 'calc(100% + 50px)', justifyContent: 'flex-end' }}>
                                        <ActionMenu actions={actions}/>
                                </div>
                            </div>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        if (this.props.items.length === 0) {
            return (
                <div>Городов не найдено</div>
            );
        }

        if(window.innerWidth > 860)
            return (
                <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                    {this.renderThead()}
                    {this.renderBody()}
                </table>
            );

        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderBodyMobile()}
            </table>
        );
        
    }
}

export default withRouter(TableCities);