export function setUImobile(field) {
    return (dispatch) => {
        dispatch({ type: 'SET_UI', field: field});
    }
}

export function setUIOpenSidebar(value) {
    return (dispatch) => {
        dispatch({ type: 'SET_UI_SIDEBAR', value: value});
    }    
}

export function setUIvisible(visibleFiltersButton, visibleActionButton) {
    return (dispatch) => {
        dispatch({ type: 'SET_UI_VISIBLE', visibleFiltersButton: visibleFiltersButton, visibleActionButton: visibleActionButton});
    }
}

export function setUIdefault() {
    return (dispatch) => {
        dispatch({ type: 'SET_UI_DEFAULT'});
    }
}
