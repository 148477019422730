import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../utils/Input';
import { setZoneEdit, fetchCities, fetchZone, updateZone } from '../../action/handbook';
import cn from 'classnames';
import Loader from '../utils/Loader';
import { isEqual, cloneDeep } from 'lodash';
import ErrorContainer from '../utils/ErrorContainer';
import { YMaps, Map, SearchControl, Placemark, Polygon } from 'react-yandex-maps';
import { setUIvisible } from '../../action/UImobile';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const reverseCoord = (coors) => {
    const clone = cloneDeep(coors);
    const result = clone[0].map(item => item.reverse());
    return [result];
}

class EditZone extends Component {
    state = {
        file: null,
        selected: null
    };

    componentDidMount() {
        this.props.fetchCities();
        this.props.fetchZone(this.props.match.params.id).then(result => {
            this.props.setZoneEdit(result);
        });
        this.props.setUIvisible(false, false);
    }

    componentWillUnmount() {
        this.props.setZoneEdit(null);
    }
    
    renderSubHeader() {
        const { edit } = this.props;
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Редактирование зоны</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc">
                            
                        </span>
                    </div>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push(`/handbook/cities/${edit.city.id}`)} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    isValidZone() {
        const { city, point, place, deliveryPrice, name, minDeliveryPrice, deliveryTime, deliveryCode, minDeliveryOrder } = this.props.edit;
        if (isEqual(this.props.detail, this.props.edit)) return false;
        if (city && point && place && (deliveryPrice || deliveryPrice === 0) && name && minDeliveryPrice && (deliveryTime || deliveryTime === 0) && (deliveryCode || deliveryCode === 0) && minDeliveryOrder) return true;
        return false;
    }

    prepareCoords(coords) {
        if (!coords) return '';
        try {
            // eslint-disable-next-line
            const parsed = JSON.parse(coords.replace((/  |\r\n|\n|\r/gm),""));
            return parsed;
        } catch (err) {
            console.log('Некорректные координаты');
            return '';
        }
    }

    setCity = (field) => (e) => {
        const data = {...this.props.edit};
       
        if (field === 'place') {
            const prepared = this.prepareCoords(e.target.value);
            data[field] = prepared;
        } else {
            data[field] = e.target.value;
        }

        this.props.setZoneEdit(data);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.updateZone(this.props.match.params.id, this.props.edit).then(result => {
            if (result) {
                this.props.history.push(`/handbook/cities/${this.props.detail.city.id}`)
            }
        });
    }

    onResultShow = () => {
        if (this.map) {
            const index = this.map.getSelectedIndex();
            const result = this.map.getResultsArray();
            const coord = result[index].geometry.getCoordinates().join(',');
            this.setCoord(coord);
          }
    };

    setCoord = (value) => {
        const data = {...this.props.edit};
        data['coord'] = value;
        this.props.setFilialEdit(data);
    }

    renderAdress(item) {
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    loadFile = (files) => {
        var file = files.target.files[0];
        if (!file) {
            const data = {...this.props.edit};
            data.place = this.props.detail.place;
            this.props.setZoneEdit(data);
            this.setState({ file: null, selected: null });
            return;
        };
        var reader = new FileReader();
        reader.onload = (e) => {
            this.setState({ file: JSON.parse(e.target.result) });
        };
        reader.readAsText(file);
    }

    setZone = (item) => (e) => {
        const data = {...this.props.edit};
        const coord = item.geometry.coordinates;

        const prepared = this.prepareCoords(JSON.stringify(coord));
        data.place = prepared;
      
        this.props.setZoneEdit(data);
        this.setState({ selected: item.id });
    }

    render() {
        const { edit, detail } = this.props;
        if (this.props.loading || this.props.cities.length === 0 || !edit) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }

        const cityId = detail.city.id;

        const currentCity = this.props.cities.filter(item => item.id === +cityId)[0];

        const points = currentCity ? currentCity.points : [];
        const defaultCoord = currentCity ? currentCity.coord.split(',').map(item => +item) : [47.222127, 39.720367];
        const currentPoint = this.props.edit.point && this.props.edit.point.id ? this.props.edit.point : this.props.edit.point ? points.filter(p => p.id === +this.props.edit.point)[0] : null;
        
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="kt-portlet">
                                <form onSubmit={this.onSubmit} className="kt-form">
                                    <div className="kt-portlet__body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Город *</label>
                                                    <select disabled={true} value={edit.city.id} onChange={this.setCity('city')} className="form-control">
                                                        <option value="">Выберите город</option>
                                                        {
                                                            this.props.cities.map((item, index) => (
                                                                <option key={index} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Точка *</label>
                                                    <select value={edit.point.id} onChange={this.setCity('point')} className="form-control">
                                                        <option value="">Выберите точку</option>
                                                        {
                                                            points.map((item, index) => (
                                                                <option key={index} value={item.id}>{this.renderAdress(item)}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input
                                                    label="Название зоны"
                                                    required={true}
                                                    onChange={this.setCity('name')}
                                                    value={this.props.edit.name}
                                                    placeholder="Название..."
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    label="Код доставки"
                                                    required={true}
                                                    onChange={this.setCity('deliveryCode')}
                                                    value={this.props.edit.deliveryCode}
                                                    placeholder="Код..."
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input
                                                    label="Стоимость доставки"
                                                    required={true}
                                                    onChange={this.setCity('deliveryPrice')}
                                                    value={this.props.edit.deliveryPrice}
                                                    placeholder="Стоимость..."
                                                    type="number"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    required={true}
                                                    label="Бесплатно от"
                                                    onChange={this.setCity('minDeliveryPrice')}
                                                    value={this.props.edit.minDeliveryPrice}
                                                    placeholder="Сумма..."
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input
                                                    required={true}
                                                    label="Минимальная сумма заказа"
                                                    onChange={this.setCity('minDeliveryOrder')}
                                                    value={this.props.edit.minDeliveryOrder}
                                                    placeholder="Сумма..."
                                                    type="number"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Input
                                                    required={true}
                                                    label="Время доставки"
                                                    onChange={this.setCity('deliveryTime')}
                                                    value={this.props.edit.deliveryTime}
                                                    placeholder="Время доставки..."
                                                    type="number"
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginBottom: 20 }}>
                                            <div className="col-md-12">
                                                <div className="yaconstructor">
                                                    <div className="yaconstructor_title">Импорт зон из Yandex Map Constructor</div>
                                                    <input type="file" onChange={this.loadFile} />
                                                    {this.state.file && (
                                                        <div className="yaconstructor_info">
                                                            <div><span>Город:</span> {this.state.file.metadata.name}</div>
                                                            <div>Выберите зону:</div>
                                                            <div className="yaconstructor_zone">
                                                                {this.state.file.features.filter(item => item.geometry.type === 'Polygon').map(item => {
                                                                    return <div className={cn({ 'active_zone': item.id === this.state.selected })} onClick={this.setZone(item)} key={item.id}>{item.properties.description}</div>;
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                        <div className="col-md-12">
                                            <Input
                                                disabled
                                                label="Координаты зоны"
                                                required={true}
                                                onChange={this.setCity('place')}
                                                value={JSON.stringify(this.props.edit.place)}
                                                placeholder="Координаты..."
                                                textarea={true}
                                                rows={10}
                                            />
                                        </div>
                                    </div>
                                             
                                    </div>
                                    <ErrorContainer field="editZone" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                                    <div className="kt-portlet__foot">
                                        <div className="kt-form__actions">
                                            <button
                                                disabled={!this.isValidZone() || this.props.loading}
                                                type="submit"
                                                className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': this.props.editZoneLoader })}>
                                                Сохранить
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <YMaps
                                style={{ padding: '25px ', marginTop: -30 }}
                                query={{
                                    apikey: '71140458-f614-4101-ad58-f75c79f0f6fe'
                                }}
                                >
                                <Map
                                    height="500px"
                                    width="100%"
                                    state={{ center: defaultCoord, zoom: 12, controls: [] }}
                                >
                                    {
                                        edit.place && (
                                            <Polygon
                                                geometry={reverseCoord(edit.place)}
                                                options={{
                                                    fill: "#56db40",
                                                    fillOpacity: 0.6,
                                                    stroke: "#56db40",
                                                    strokeWidth: "1",
                                                    strokeOpacity: 0.9
                                                }}
                                            />
                                        )
                                    }
                                    {
                                        currentPoint && (
                                            <Placemark geometry={currentPoint.coord.split(',')} />
                                        )
                                    }
                                
                                    <SearchControl instanceRef={ref => this.map = ref} onResultShow={this.onResultShow} options={{ float: 'right' }}/>
                                </Map>
                            </YMaps>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    edit: state.handbook.zone.edit,
    detail: state.handbook.zone.detail,
    loading: state.loaders.zone,
    editZoneLoader: state.loaders.editZone,
    cities: state.handbook.cities
});

const mapDispatchToProps = {
    fetchZone: (id) => fetchZone(id),
    setZoneEdit: (data) => setZoneEdit(data),
    fetchCities: () => fetchCities(true),
    updateZone: (id, data) => updateZone(id, data),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditZone);