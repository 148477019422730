import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../utils/Loader';
import { setStatFilters, resetStatFilters, fetchPromocodeStat } from '../../action/statistics';
import { fetchCities } from '../../action/handbook';
import Select from 'react-select';
import DateInput from '../utils/DateInput';
import moment from 'moment';


const styles = {
    loading: {
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center'
    }
}

function compare( a, b ) {
    if ( a.value < b.value ){
      return 1;
    }
    if ( a.value > b.value ){
      return -1;
    }
    return 0;
  }

class Table extends Component {
    renderPromo = (promocode, index) => {
        return (
            <tr key={index}>
                <td>
                    {promocode.name}
                </td>
                <td className="kt-align-right kt-font-brand kt-font-bold"> {promocode.value}</td>
            </tr>
        );  
    }

    render() {
        if (!this.props.items || this.props.items.length === 0) {
            return 'По данным критериям нет промокодов';
        }

        return (
           
            <div className="kt-widget11">
                <div className="table-responsive">					 
                    <table className="table">
                        <thead>
                            <tr>
                                <td style={{ width: '85%' }}>Промокод</td>
                                <td style={{ width: '15%' }} className="kt-align-right">Кол-во использования</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.items.sort(compare).map(this.renderPromo)
                            }
                        </tbody>									     
                    </table>
                </div>
            </div>
        );
    }
}

class Promocode extends Component {
    componentDidMount() {
       this.props.fetchCities();
       this.props.fetchPromocodeStat();
    }

    setFilters = (field) => (data) => {
        const { filters } = this.props.orders;
        const updated = {
            ...filters,
            [field]: data
        }
        this.props.setStatFilters('promocode', updated);
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props.promocode;

        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }

        this.props.setStatFilters('promocode', updated);
    }

    resetFilter = () => {
        this.props.resetStatFilters('promocode');
        
    }

    onSearch = () => {
        this.props.fetchPromocodeStat();
    }

    isDisabled = () => {
        const { promocode } = this.props
        const { filters } = promocode;

        if (filters.createdStartDate && filters.createdEndDate) {
            return false;
        }
        return true;
    }

    render() {
        const { promocode } = this.props
        const { result, loading, filters } = promocode;

        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head" style={{ flexDirection: 'column', gap: '10px' }}>
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Промокоды
                        </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar"  style={{ flexWrap: 'wrap', gap: '10px' }}>
                        <div className="dateBlock">
                            <DateInput
                                disabled={loading}
                                placeholderText="Дата от"
                                selected={filters.createdStartDate}
                                onChange={this.onChangeDateInput('createdStartDate')}
                                maxDate={moment().toDate()}
                            />
                            <div>-</div>
                            <DateInput
                                disabled={loading}
                                placeholderText="Дата до"
                                selected={filters.createdEndDate}
                                onChange={this.onChangeDateInput('createdEndDate')}
                                maxDate={moment(filters.createdStartDate).add({ days: filters.city ? 30 : 15 }).toDate()}
                                required={filters.createdStartDate}
                            />
                        </div>
                        <div style={{ width: 250 }}>
                            <Select
                                isLoading={this.props.loadingCity || loading}
                                isDisabled={this.props.loadingCity || loading}
                                value={filters.city}
                                closeMenuOnSelect={true}
                                options={this.props.cities.map(item => ({
                                    value: item.id,
                                    label: item.name
                                }))}
                                onChange={this.setFilters('city')}
                                placeholder="Город"
                                noOptionsMessage={() => 'Нет городов'}
                            />
                        </div>
                        <button disabled={loading || this.isDisabled()} onClick={this.onSearch} className="btn btn-primary">Найти</button>
                        <button disabled={loading} onClick={this.resetFilter} className="btn btn-danger">Сбросить</button>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    {
                        loading || !result ? (
                            <div style={styles.loading}><Loader /></div>
                        ) : (
                            <Table items={this.props.promocode.result}/>
                        )
                    }
                </div>
            </div>
                        
        );
    }
}

const mapStateToProps = state => {
    return ({
        promocode: state.statistics.promocode,
        cities: state.handbook.cities,
        loadingCity: state.loaders.cities,
    });
};

const mapDispatchToProps = {
    fetchPromocodeStat: () => fetchPromocodeStat(123),
    fetchCities: () => fetchCities(true),
    setStatFilters: (name, filters) => setStatFilters(name, filters),
    resetStatFilters: (name) => resetStatFilters(name),
}

export default connect(mapStateToProps, mapDispatchToProps)(Promocode);