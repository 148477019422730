import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addModification, setActiveModalAction, updateModification } from '../../action';
import { useOnClickOutside } from '../../hooks';
import { fromStore, modalsSelector } from '../../selectors';
import cn from 'classnames';
import ErrorContainer from '../../components/utils/ErrorContainer';
import Switch from '../../components/utils/Switch';
import Select from 'react-select';
import { CONDITIONS, MEASURES } from '../goods/constants';
import { getNewPrice } from '../../utils/common';

export function ModificationModal() {
  const ref = useRef();
  const dispatch = useDispatch();
  const id = useSelector(modalsSelector).modification;
  const loadingCreate = useSelector(fromStore.loaderModalModificationSelector);
  const loadingUpdate = useSelector(fromStore.loaderUpdateModificationSelector);
  const detail = useSelector(fromStore.detailGoodSelector);
  const loading = loadingUpdate || loadingCreate;

  const modification = detail && detail.modifications ? detail.modifications.find((item) => item.id === id) : null;
  const modificationIds = detail && detail.modifications ? detail.modifications.map((item) => item.id) : [];

  const initData = modification ? {
    goodId: detail.id,
    title: modification.title,
    price: modification.price,
    oldPrice: modification.oldPrice,
    weight: modification.weight,
    default: modification.default,
    active: modification.active,
    condition: modification.condition || 'plus'
  } : {
    goodId: detail.id,
    ids: modificationIds,
    title: '',
    condition: 'plus',
    default: false,
    active: true,
  };

  const [data, setData] = useState(initData);
  const isEditMode = id !== 'new';

  const onChangeNumberInput = (field) => (e) => {
    const updated = {...data};
    updated[field] = +e.target.value;
    setData(updated);
  };

  const onChangeSwitch = (field) => (value) => {
    const updated = {...data};
    updated[field] = value;
    setData(updated);
  };

  const isValid = () => {
    const { price, title } = data;
    if (isEditMode) {
      if (price !== undefined && title) return true;
    }
    if (price !== undefined && title) return true;

    return false;
  }

  const onClose = () => !loading && dispatch(setActiveModalAction({ field: 'modification', value: null }));
  useOnClickOutside(ref, onClose);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isEditMode) {
      const result = await dispatch(addModification(data));
      if (result) {
        onClose();
      }
    } else {
      const result = await dispatch(updateModification(id, data));
      if (result) {
        onClose();
      }
    }
  };

  const onChangeSelect = (field) => (value) => {
    const updated = {...data};
    if (field === 'condition') {
      updated[field] = value.value;
    } else {
      updated[field] = value;
    }
    setData(updated);
  }

  const onChangeInput = (field) => (e) => {
    const updated = {...data};
    updated[field] = e.target.value;
    setData(updated);
  };

  const conditionsOptions = Object.entries(CONDITIONS).map(([value, label]) => ({ value, label }));
  const selectedCondition = { value: data.condition, label: CONDITIONS[data.condition] };

  return (
    <form onSubmit={onSubmit}>
      <div  className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div ref={ref} className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isEditMode ? 'Редактирование модификации' : 'Добавление модификации'} </h5>
              <button onClick={onClose} type="button" className="close">
              </button>
            </div>
            <div className="modal-body">
              <div className='row'>
                  <div className='col-6'>
                      <div className="form-group">
                        <label className="form-control-label">Заголовок *</label>
                        <input disabled={loading} type="text" value={data.title} className="form-control" onChange={onChangeInput('title')} />
                      </div>
                  </div>
              </div>
               
                <div className='row'>
                  <div className='col-4'>
                    <div className="form-group">
                      <label className="form-control-label">Условие *</label>
                        <Select
                          value={selectedCondition}
                          options={conditionsOptions}
                          onChange={onChangeSelect('condition')}
                          isClearable={false}
                          placeholder="Выберите..."
                          disabled={loading}
                        />
                    </div>
                  </div>
                  {window.innerWidth > 1024 && 
                  <>
                    <div className='col-4'>
                      <div className="form-group">
                        <label className="form-control-label">Цена(Базовая: {detail.price} ₽) *</label>
                        <input disabled={loading} type="number" value={data.price} className="form-control" onChange={onChangeNumberInput('price')} />
                        { data.price > 0 && (
                          <span class="form-text text-muted">
                            Новое значение: {getNewPrice(detail.price, data.condition, data.price, data.persent)} ₽
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className="form-group">
                        <label className="form-control-label">Старая цена(Базовая: {detail.oldPrice || 0} ₽)</label>
                        <input disabled={loading} type="number" value={data.oldPrice} className="form-control" onChange={onChangeNumberInput('oldPrice')} />
                        { data.oldPrice > 0 && (
                          <span class="form-text text-muted">
                            Новое значение: {getNewPrice(detail.oldPrice, data.condition, data.oldPrice, data.persent)} ₽
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                  }
                </div>

                {window.innerWidth <= 1024 && 
                <div className='row'>
                  <div className='col-4' style={{flex: '0 0 50%', maxWidth: '50%'}}>
                      <div className="form-group">
                        <label className="form-control-label">Цена(Базовая: {detail.price} ₽) *</label>
                        <input disabled={loading} type="number" value={data.price} className="form-control" onChange={onChangeNumberInput('price')} />
                        { data.price > 0 && (
                          <span class="form-text text-muted">
                            Новое значение: {getNewPrice(detail.price, data.condition, data.price, data.persent)} ₽
                          </span>
                        )}
                      </div>
                    </div>
                    <div className='col-4' style={{flex: '0 0 50%', maxWidth: '50%'}}>
                      <div className="form-group">
                        <label className="form-control-label">Старая цена(Базовая: {detail.oldPrice || 0} ₽)</label>
                        <input disabled={loading} type="number" value={data.oldPrice} className="form-control" onChange={onChangeNumberInput('oldPrice')} />
                        { data.oldPrice > 0 && (
                          <span class="form-text text-muted">
                            Новое значение: {getNewPrice(detail.oldPrice, data.condition, data.oldPrice, data.persent)} ₽
                          </span>
                        )}
                      </div>
                    </div>
                </div>
                }
                <div className="form-group">
                  <label className="form-control-label">Вес/объем (Базовый: { detail.weight } {MEASURES[detail.measure]})</label>
                  <input disabled={loading} type="text" value={data.weight} className="form-control" onChange={onChangeInput('weight')} />
                  { data.weight > 0 && (
                    <span class="form-text text-muted">
                      Новое значение: {data.weight} {MEASURES[detail.measure]}
                    </span>
                  )}
                </div>
                <div className="form-group row" style={{ marginBottom: 0 }}>
                    <label className="col-xl-3 col-lg-3 col-form-label">Включено</label>
                    <div className="col-lg-9 col-xl-9">
                        <Switch nomargin={true} onChange={onChangeSwitch('active')} checked={!!data.active}/>
                    </div>
                </div>
                <div className="form-group row" style={{ marginBottom: 0 }}>
                    <label className="col-xl-3 col-lg-3 col-form-label">По умолчанию</label>
                    <div className="col-lg-9 col-xl-9">
                        <Switch nomargin={true} onChange={onChangeSwitch('default')} checked={!!data.default}/>
                    </div>
                </div>
                <ErrorContainer field="modalModification" hasCloseButton={true}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={onClose}>Отмена</button>
              <button
                type="submit"
                disabled={!isValid() || loading}
                className={cn({ 'btn btn-primary  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loading })}>
                  {isEditMode ? 'Сохранить' : 'Добавить модификацию'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
