import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGoods, fetchCategories, setActiveCategory } from '../../action/goods';
import ReactTooltip from "react-tooltip";
import { fromStore } from "../../selectors";
import { isEmpty } from "lodash";
import Loader from "../utils/Loader";
import { styles } from "../../containers/goods/styles";
import '../../styles/neworder.css'
import { fetchSettings } from "../../action/app";
import Categories from "./Categories";
import Goods from "./Goods";
import DetailNewOrder from "./DetailNewOrder";
import { fetchFilials } from "../../action/handbook";
import OrderPositionList from "./OrderPositionList";
import { addPositionNewOrder, changeIsAggPriceNewOrder, clearNewOrder, openEditOrder } from "../../action/neworder";
import { fetchOrder } from "../../action/orders";
import { roleId, urls } from "../../constants/app";
import request from "../../action/utils";
import { setUIvisible } from "../../action/UImobile";

function NewOrder() {
    const dispatch = useDispatch();
    const loadingGoods = useSelector(fromStore.loaderGoodsSelector);
    const settings = useSelector(state => state.settings.init.categories);
    const goodsChek = useSelector(state => state.good.list);
    const loadingCategories = useSelector(fromStore.loaderCategoriesSelector);
    const categories = useSelector(fromStore.categoryListSelector);
    const activeCategory = useSelector(fromStore.activeCategorySelector);    
    const loadingFilials = useSelector(state => state.loaders.filials);
    const neworders = useSelector(state => state.neworders)
    const loadingSaveOrder = useSelector(state => state.loaders.loadingSaveOrder);
    const editedOrder = useSelector(state => state.orders.detail);
    const filials = useSelector(state => state.handbook.filials);
    const UImobile = useSelector(state => state.UImobile);
    const user = useSelector(state => state.user.info)
    
    const [goods, setGoods] = useState([]);
    const [searchGoods, setSearchGoods]  = useState(null);
    const [searchingGoods, setSearchingGoods]  = useState(null);
    const [editOrder, setEditOrder] = useState(false);
    const [activeTabMobile, setActiveTabMobile] = useState('detail')

    const onSelectCategory = (id) => () => {        
        dispatch(setActiveCategory(id));            
    };

    const checkModPrice = (field, good, mod) => {
        if(mod.default) {
            if(field === 'price') 
                return good.defaultPrice;
            return good.defaultOldPrice; 
        }
            if(mod.active) {
                switch(mod.condition) {
                    case('equal'): {
                    if(mod[field]) {
                        return mod[field];
                    } else {
                        if(good[field])
                            return good[field];
                        return null;
                    }
                }
                case('plus'): {
                    if(good[field]) {
                        if(mod[field]) {
                                return Math.round((good[field] + mod[field]).toFixed(0));
                        } else {
                            return good[field];
                        }
                    } else {
                        return null;
                    }
                }
                case('minus'): {
                    if(good[field]) {
                        if(mod[field]) {
                            return Math.round((good[field] - mod[field]).toFixed(0));
                        } else {
                            return good[field];
                        }
                    } else {
                        return null;
                    }
                }
                case('multiply'): {
                    if(good[field]) {
                        if(mod[field]) {
                            return Math.round((good[field] * mod[field]).toFixed(0));
                        } else {
                            return good[field];
                        }
                    } else {
                        return null;
                    }
                }                
                    default: {
                    if(good[field]) {
                        return good[field]; 
                        } else {
                            return null;
                        }
                    }
                }
            } else {
                return null;
            }
    }

    const changeModification = (id, mod) => {       
            const updatedGoods = goods.map(item =>
                item.id === id ? { 
                    ...item, 
                    id: !mod.default ? `${item.id}_${mod.id}` : (String(item.id).split('_')[0] ? String(item.id).split('_')[0] : item.id),
                    isMod: !mod.default,
                    defaultPrice: !mod.default ? item.price : item.defaultPrice,
                    defaultOldPrice: !mod.default ? item.oldPrice : item.defaultOldPrice,
                    price: checkModPrice('price', item, mod),
                    oldPrice: checkModPrice('oldPrice', item, mod)
                } : item
            );
        
        setGoods(updatedGoods);
    }

    const checkRegionPrice = (field, good, regionPrice) => {
        if(regionPrice.active) {
            switch(regionPrice.condition) {
                case('equal'): {
                    if(regionPrice[field]) {
                        return regionPrice[field];
                    } else {
                        if(good[field])
                            return good[field];
                        return null;
                    }
                }
                case('plus'): {
                    if(good[field]) {
                        if(regionPrice[field]) {
                            if(regionPrice.persent)                        
                                    return Math.round((good[field] + (good[field] / 100 * regionPrice[field])));
                                return Math.round((good[field] + regionPrice[field]).toFixed(0));
                        } else {
                            return good[field];
                        }
                    } else {
                        return null;
                    }
                }
                case('minus'): {
                    if(good[field]) {
                        if(regionPrice[field]) {
                            if(regionPrice.persent)
                                return Math.round((good[field] - (good[field] / 100 * regionPrice[field])).toFixed(0));
                            return Math.round((good[field] - regionPrice[field]).toFixed(0));
                        } else {
                            return good[field];
                        }
                    } else {
                        return null;
                    }
                }
                case('multiply'): {
                    if(good[field]) {
                        if(regionPrice[field]) {
                            return Math.round((good[field] * regionPrice[field]).toFixed(0));
                        } else {
                            return good[field];
                        }
                    } else {
                        return null;
                    }
                }                
                    default: {
                    if(good[field]) {
                        return good[field]; 
                        } else {
                            return null;
                        }
                    }
            }
        } else {
            return good[field];
        }
    }

    const filterGoods = useCallback(() => {
        if(settings && activeCategory !== settings.wok)
            if(!neworders.point)
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory)
                        .sort((a, b) => {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                        })
                );
            }
            else
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory)
                        .sort((a, b) => {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                        })
                        .filter((item) => { //проверка на доступность позиции в городе
                            const disabledCityIds = item.disabledCities ? item.disabledCities.split(',') : [];
                            return !disabledCityIds.some((id) => parseInt(id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id));
                        })
                        .map((item) => //проверка региональных цен
                            item.regionprices.find((regionprices) => 
                                regionprices.cities.some((item) => parseInt(item.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id)))
                            ?
                            { ...item, 
                                price: checkRegionPrice('price', item, item.regionprices.find((regionprices) => regionprices.cities.some((item) => parseInt(item.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id)))), 
                                oldPrice: checkRegionPrice('oldPrice', item, item.regionprices.find((regionprices) => regionprices.cities.some((item) => parseInt(item.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id))))
                            } 
                            : 
                            item                   
                        )
                );                
            }

        if(settings && activeCategory === settings.wok)            
            if(!neworders.point)
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory || item.category.id === settings.mainWok)
                        .sort((a, b) => {
                            if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                        })
                            .sort((a, b) => b.category.id - a.category.id)
                            .map((item) => 
                                item.category.id === settings.mainWok 
                                ?
                                { ...item, isWok: true }
                                :
                                item
                            )
                        );
            }
            else
            {
                setGoods(goodsChek.filter((item) => item.category.id === activeCategory || item.category.id === settings.mainWok)
                    .sort((a, b) => {
                        if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                        if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                        return 0;
                    })
                        .sort((a, b) => b.category.id - a.category.id)
                        .map((item) => {
                            return { ...item, isWok: true }
                        })
                        .filter((item) => { //проверка на доступность позиции в городе
                            const disabledCityIds = item.disabledCities ? item.disabledCities.split(',') : [];
                            return !disabledCityIds.some((id) => parseInt(id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id));
                        })
                        .map((item) => //проверка региональных цен
                            item.regionprices.find((regionprices) => 
                                regionprices.cities.some((item) => parseInt(item.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id)))
                            ?
                            { ...item, 
                                price: checkRegionPrice('price', item, item.regionprices.find((regionprices) => regionprices.cities.some((item) => parseInt(item.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id)))), 
                                oldPrice: checkRegionPrice('oldPrice', item, item.regionprices.find((regionprices) => regionprices.cities.some((item) => parseInt(item.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id))))
                            } 
                            : 
                            item                   
                        )
                    );
            }
                // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCategory, goodsChek, settings, neworders.point]);

    useEffect(() => {
        filterGoods();
        dispatch(setUIvisible(false, false))
    }, [filterGoods, dispatch]);

    useEffect(() => {
        if(searchGoods && goodsChek)
            setSearchingGoods(goodsChek.filter(good => good.name.trim().toLowerCase().replace(/[,\s.]/gi, '').includes(searchGoods.trim().toLowerCase().replace(/[,\s.]/gi, '')))
            .filter((item) => { //проверка на доступность позиции в городе
                const disabledCityIds = item.disabledCities ? item.disabledCities.split(',') : [];
                return !disabledCityIds.some((id) => parseInt(id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id));
            }).slice(0, 5)
            .map((item) => //проверка региональных цен
                item.regionprices.find((regionprices) => 
                    regionprices.cities.some((item) => parseInt(item.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id)))
                ?
                { ...item, 
                    price: checkRegionPrice('price', item, item.regionprices.find((regionprices) => regionprices.cities.some((item) => parseInt(item.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id)))), 
                    oldPrice: checkRegionPrice('oldPrice', item, item.regionprices.find((regionprices) => regionprices.cities.some((item) => parseInt(item.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id))))
                } 
                : 
                item                   
            ))

        if(!searchGoods)
            setSearchingGoods(null)
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchGoods, goodsChek, neworders.point])

    useEffect(() => {      
        if(window.location.pathname.includes('/edit'))
        {
            const currentUrl = window.location.href;
            const urlParts = currentUrl.split('/');
            const id = urlParts[urlParts.length - 1];

            setEditOrder(true);
            if(window.location.pathname.includes('/orders'))
                dispatch(fetchOrder(id, false));
            if(window.location.pathname.includes('/archive'))
                dispatch(fetchOrder(id, true));
        } else {
            setEditOrder(false);
            dispatch(clearNewOrder());
            dispatch({ type: 'SET_ORDER', order: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        const checkFilial = async () => {
            if(editedOrder.point) 
                return editedOrder.point;

            if(editedOrder.zone) {
                if(editedOrder.zone.point.id) {
                    return editedOrder.zone.point;
                } else {
                    if(filials.length > 0) {
                       return await filials.find((filial) => filial.id === editedOrder.zone.point);
                    } else {
                        return await request({ method: 'get', url: `${urls.filials}/${editedOrder.zone.point}` });
                    }
                }
            }

            if(!editedOrder.point && !editedOrder.zone) {
                return null;
            }
        }

        const fetchFilialAndDispatch = async () => {
            if (editedOrder) {
                const point = await checkFilial(); 
                dispatch(openEditOrder(editedOrder, point, goodsChek)); 
            }
        };

        fetchFilialAndDispatch(); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedOrder, dispatch])

    useEffect(() => ReactTooltip.rebuild(), [goods, loadingGoods]);

    useEffect(() => {   
        isEmpty(settings) && dispatch(fetchSettings());
        isEmpty(goodsChek) && dispatch(fetchGoods());
        isEmpty(categories) && dispatch(fetchCategories());
        dispatch(fetchFilials(null, true));
        return () => {
            setTimeout(() => {
                dispatch(clearNewOrder());
                dispatch({ type: 'SET_ORDER', order: null });
                setEditOrder(false);
            }, 50);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const renderContent = () => {
        if(loadingGoods || loadingCategories || loadingFilials)
            return(
                <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                    <div style={styles.tableLoading}><Loader text='Загружаю данные'/></div>
                </div>
            )

        if(loadingSaveOrder)
            return(
                <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                    <div style={styles.tableLoading}><Loader text='Сохраняю заказ'/></div>
                </div>
            )

        return(
            <div className='kt-container neworder kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop neworder">
                    <div className={`neworder_container${UImobile.openSidebar ? ' openSidebar' : ''} order_position_list`}>
                        <OrderPositionList editOrder={editOrder} loadingGoods={loadingGoods} goods={goodsChek}/>
                    </div>
                    <div className={`neworder_container${UImobile.openSidebar ? ' openSidebar' : ''} detail`} >
                        <DetailNewOrder editOrder={editOrder}/>
                    </div>
                    <div className={`neworder_container${UImobile.openSidebar ? ' openSidebar' : ''} categories`}>
                       <Categories categories={categories} activeCategory={activeCategory} onSelectCategory={onSelectCategory} UImobile={UImobile}/>
                    </div>
                    <div className="neworder_good_main">
                        <div className={`neworder_good_head${UImobile.openSidebar ? ' openSidebar' : ''}`}>                            
                            <div style={{display: "flex", gap: '10px', alignItems: 'center', marginTop: '10px'}}>
                                {(!editOrder || user.role.id !== roleId.cashier) &&
                                <>
                                    <label>{window.innerWidth > 1210 ? 'Цены агрегаторов' : 'Цены аг.'}</label>
                                    <div>
                                        <span className="kt-switch kt-switch--sm kt-switch--icon">
                                        <label>
                                            <input 
                                            type="checkbox" 
                                            checked={neworders.isAggPrice} 
                                            onChange={(e) => dispatch(changeIsAggPriceNewOrder(e.target.checked, goodsChek))} 
                                            />
                                            <span className="new_order_switch"></span>
                                        </label>
                                        </span>
                                    </div>
                                </>
                                }
                            </div>
                            <div className="neworder_good_head_search_block">
                                {neworders.point && goodsChek &&
                                    <>
                                        <input 
                                            type="text"
                                            className="form-control search"
                                            autocomplete="false"
                                            placeholder="Поиск"
                                            value={searchGoods}
                                            onChange={((e) => setSearchGoods(e.target.value))}
                                            onBlur={(() => setSearchGoods(''))}                                            
                                        />
                                        {searchGoods && searchingGoods && searchingGoods.length > 0 &&
                                            <div className="new_order_search_div">
                                                <div className="new_order_search_container">
                                                    {searchingGoods.map((item) => 
                                                        <button
                                                        className={`new_order_search_button ${neworders.point.stopList.some((el) => el.value === item.id) ? 'unactived' : ''}`}
                                                        onMouseDown={((e) => e.preventDefault())}
                                                        onClick={(() => {
                                                            if(!neworders.point.stopList.some((el) => el.value === item.id))
                                                            {
                                                                dispatch(addPositionNewOrder(item, settings));
                                                                setSearchGoods('');
                                                            }
                                                        })}
                                                        >
                                                            <span className="neworder_good_button_text" >{item.name}</span>
                                                            {!neworders.isAggPrice 
                                                            ?
                                                            <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.9rem' }}> {`${item.isMod ? item.price + item.isMod.price : item.price} ₽`} </span>
                                                            :
                                                            <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.9rem' }}> {`${item.isMod ? item.oldPrice + item.isMod.oldPrice : item.oldPrice} ₽`} </span>
                                                            }
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className={`neworder_container${UImobile.openSidebar ? ' openSidebar' : ''} goods`}>
                            <Goods loadingGoods={loadingGoods} goods={goods} changeModification={changeModification} setActiveTabMobile={setActiveTabMobile}/>
                            <ReactTooltip
                                effect="solid"
                                type="dark"
                                place="top"
                            />       
                        </div>  
                    </div>    
                </div>
            </div>
        )
    }

    const renderleftPanelMobile = () => {
        if(activeTabMobile === 'detail')
            return(
                <div className={`neworder_container${UImobile.openSidebar ? ' openSidebar' : ''} detail`} >
                    <DetailNewOrder editOrder={editOrder}/>
                </div>
            );

        if(activeTabMobile === 'goods') {
            if(window.innerWidth > 650)
                setActiveTabMobile('detail')
            return (<div className="neworder_container goods_mobile">
                <div className="neworder_container categories">
                       <Categories categories={categories} activeCategory={activeCategory} onSelectCategory={onSelectCategory}/>
                    </div>
                    <div className="neworder_good_main">
                        <div className={`neworder_good_head${UImobile.openSidebar ? ' openSidebar' : ''}`}>                            
                            <div style={{display: "flex", gap: '10px', alignItems: 'center', marginTop: '10px'}}>
                                {(!editOrder || user.role.id !== roleId.cashier) &&
                                    <>
                                        <label>{window.innerWidth > 1210 ? 'Цены агрегаторов' : 'Цены аг.'}</label>
                                        <div>
                                            <span className="kt-switch kt-switch--sm kt-switch--icon">
                                            <label>
                                                <input 
                                                type="checkbox" 
                                                checked={neworders.isAggPrice} 
                                                onChange={(e) => dispatch(changeIsAggPriceNewOrder(e.target.checked, goodsChek))} 
                                                />
                                                <span className="new_order_switch"></span>
                                            </label>
                                            </span>
                                        </div>
                                    </>
                                    }
                            </div>
                            <div className="neworder_good_head_search_block">
                                {neworders.point && goodsChek &&
                                    <>
                                        <input 
                                            type="text"
                                            className="form-control search"
                                            autocomplete="false"
                                            placeholder="Поиск"
                                            value={searchGoods}
                                            onChange={((e) => setSearchGoods(e.target.value))}
                                            onBlur={(() => setSearchGoods(''))}                                            
                                        />
                                        {searchGoods && searchingGoods && searchingGoods.length > 0 &&
                                            <div className="new_order_search_div">
                                                <div className="new_order_search_container">
                                                    {searchingGoods.map((item) => 
                                                        <button
                                                        className={`new_order_search_button ${neworders.point.stopList.some((el) => el.value === item.id) ? 'unactived' : ''}`}
                                                        onMouseDown={((e) => e.preventDefault())}
                                                        onClick={(() => {
                                                            if(!neworders.point.stopList.some((el) => el.value === item.id))
                                                            {
                                                                dispatch(addPositionNewOrder(item, settings));
                                                                setSearchGoods('');
                                                            }
                                                        })}
                                                        >
                                                            <span className="neworder_good_button_text" >{item.name}</span>
                                                            {!neworders.isAggPrice 
                                                            ?
                                                            <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.9rem' }}> {`${item.isMod ? item.price + item.isMod.price : item.price} ₽`} </span>
                                                            :
                                                            <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.9rem' }}> {`${item.isMod ? item.oldPrice + item.isMod.oldPrice : item.oldPrice} ₽`} </span>
                                                            }
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className="neworder_container goods">
                            <Goods loadingGoods={loadingGoods} goods={goods} changeModification={changeModification} setActiveTabMobile={setActiveTabMobile}/>
                            <ReactTooltip
                                effect="solid"
                                type="dark"
                                place="top"
                            />       
                        </div>  
                    </div> 
            </div>);
        }

        return (
            <div className="neworder_container order_position_list">         
                <OrderPositionList editOrder={editOrder} loadingGoods={loadingGoods} goods={goodsChek}/>;               
            </div>
        );
    }

    const renderContentTablet = () => {
        if(loadingGoods || loadingCategories || loadingFilials)
            return(
                <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                    <div style={styles.tableLoading}><Loader text='Загружаю данные'/></div>
                </div>
            )

        if(loadingSaveOrder)
            return(
                <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                    <div style={styles.tableLoading}><Loader text='Сохраняю заказ'/></div>
                </div>
            )

        return(
            <div className='kt-container neworder kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px'}}>
                        <div className="tab_mobile-buttons_container">
                            <button 
                            className={`tab_mobile-button ${activeTabMobile === 'positionList' ? ' active' : ''}`}
                            onClick={(() => setActiveTabMobile('positionList'))}
                            >
                                Заказ
                            </button>
                            <button 
                            className={`tab_mobile-button ${activeTabMobile === 'detail' ? ' active' : ''}`}
                            onClick={(() => setActiveTabMobile('detail'))}
                            >
                                Детали
                            </button>                            
                        </div>                
                    <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop neworder">

                        {renderleftPanelMobile()}                      

                        <div className="neworder_container categories">
                        <Categories categories={categories} activeCategory={activeCategory} onSelectCategory={onSelectCategory}/>
                        </div>
                        <div className="neworder_good_main">
                            <div className={`neworder_good_head${UImobile.openSidebar ? ' openSidebar' : ''}`}>                            
                                <div style={{display: "flex", gap: '10px', alignItems: 'center', marginTop: '10px'}}>
                                    {(!editOrder || user.role.id !== roleId.cashier) &&
                                    <>
                                        <label>{window.innerWidth > 1210 ? 'Цены агрегаторов' : 'Цены аг.'}</label>
                                        <div>
                                            <span className="kt-switch kt-switch--sm kt-switch--icon">
                                            <label>
                                                <input 
                                                type="checkbox" 
                                                checked={neworders.isAggPrice} 
                                                onChange={(e) => dispatch(changeIsAggPriceNewOrder(e.target.checked, goodsChek))} 
                                                />
                                                <span className="new_order_switch"></span>
                                            </label>
                                            </span>
                                        </div>
                                    </>
                                    }
                                </div>
                                <div className="neworder_good_head_search_block">
                                    {neworders.point && goodsChek &&
                                        <>
                                            <input 
                                                type="text"
                                                className="form-control search"
                                                autocomplete="false"
                                                placeholder="Поиск"
                                                value={searchGoods}
                                                onChange={((e) => setSearchGoods(e.target.value))}
                                                onBlur={(() => setSearchGoods(''))}                                            
                                            />
                                            {searchGoods && searchingGoods && searchingGoods.length > 0 &&
                                                <div className="new_order_search_div">
                                                    <div className="new_order_search_container">
                                                        {searchingGoods.map((item) => 
                                                            <button
                                                            className={`new_order_search_button ${neworders.point.stopList.some((el) => el.value === item.id) ? 'unactived' : ''}`}
                                                            onMouseDown={((e) => e.preventDefault())}
                                                            onClick={(() => {
                                                                if(!neworders.point.stopList.some((el) => el.value === item.id))
                                                                {
                                                                    dispatch(addPositionNewOrder(item, settings));
                                                                    setSearchGoods('');
                                                                }
                                                            })}
                                                            >
                                                                <span className="neworder_good_button_text" >{item.name}</span>
                                                                {!neworders.isAggPrice 
                                                                ?
                                                                <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.9rem' }}> {`${item.isMod ? item.price + item.isMod.price : item.price} ₽`} </span>
                                                                :
                                                                <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.9rem' }}> {`${item.isMod ? item.oldPrice + item.isMod.oldPrice : item.oldPrice} ₽`} </span>
                                                                }
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="neworder_container goods">
                                <Goods loadingGoods={loadingGoods} goods={goods} changeModification={changeModification} setActiveTabMobile={setActiveTabMobile} UImobile={UImobile}/>
                                <ReactTooltip
                                    effect="solid"
                                    type="dark"
                                    place="top"
                                />       
                            </div>  
                        </div>  
                    </div>
                </div>
            </div>            
        );
    }

    const renderContentMobile = () => {
        if(loadingGoods || loadingCategories || loadingFilials)
            return(
                <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                    <div style={styles.tableLoading}><Loader text='Загружаю данные'/></div>
                </div>
            )

        if(loadingSaveOrder)
            return(
                <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                    <div style={styles.tableLoading}><Loader text='Сохраняю заказ'/></div>
                </div>
            )

        return(
            <div className='kt-container neworder kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px'}}>
                        <div className="tab_mobile-buttons_container">
                            <button 
                            className={`tab_mobile-button ${activeTabMobile === 'goods' ? ' active' : ''}`}
                            onClick={(() => setActiveTabMobile('goods'))}
                            >
                                Товары
                            </button>
                            <button 
                            className={`tab_mobile-button ${activeTabMobile === 'positionList' ? ' active' : ''}`}
                            onClick={(() => setActiveTabMobile('positionList'))}
                            >
                                Заказ
                                {parseFloat(neworders.totalPrice) > 0 && <div>
                                    <div className="tab_mobile-button_total-price">
                                        {parseFloat(neworders.totalPrice).toFixed(0)}
                                    </div>
                                </div>}
                            </button>
                            <button 
                            className={`tab_mobile-button ${activeTabMobile === 'detail' ? ' active' : ''}`}
                            onClick={(() => setActiveTabMobile('detail'))}
                            >
                                Детали
                            </button>                            
                        </div>                
                    <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop neworder"> 
                        {renderleftPanelMobile()}                        
                    </div>
                </div>
            </div>            
        );
    }
          

    if(window.innerWidth <= 640)
        return (
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor paddingTop-mobile">
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" style={{padding: '0px 5px'}}>                
                    {renderContentMobile()}                                                    
                </div>
            </div>
        );
    
    if(window.innerWidth <= 930 || (window.innerWidth <= 1130 && UImobile.openSidebar && window.innerWidth > 1024))
        return (
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor paddingTop-mobile">
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" style={{padding: '0px 5px'}}>                
                    {renderContentTablet()}                                                    
                </div>
            </div>
        );

        return (
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor paddingTop-mobile">
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">                
                    {renderContent()}                                                    
                </div>
            </div>
        );
    
}

export default NewOrder;