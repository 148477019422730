import React, { useEffect } from 'react';
import { ParamsGood } from './ParamsGood';
import { Tabs } from './Tabs';
import { useDispatch } from 'react-redux';
import { setUIvisible } from '../../../action/UImobile';

export function DetailContent() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setUIvisible(false, false));
    }, [dispatch]);
    
    return (
        <div className="row">
            <div className="col-xl-3">
                <ParamsGood />
            </div>
            <div className="col-xl-9">
                <Tabs />
            </div>
        </div>
    );
}