import Settings from '../pages/Settings';
import OrdersPage from '../pages/OrdersPage';
import HandbookPage from '../pages/HandbookPage';
import AddCity from '../components/handbook/AddCity';
import CityList from '../components/handbook/CityList';
import FilialList from '../components/handbook/FilialList';
import AddFilial from '../components/handbook/AddFilial'; 
import AddZone from '../components/handbook/AddZone'; 
import EditCity from '../components/handbook/EditCity';
import DetailCity from '../components/handbook/DetailCity';
import Dashboard from '../components/dashboard/Dashboard';
import EditFilial from '../components/handbook/EditFilial';
import EditZone from '../components/handbook/EditZone';
import DetailFilial from '../components/handbook/DetailFilial';
import OrdersList from '../components/orders/OrdersList';
import PromocodesPage from '../pages/PromocodesPage';
import PushMessage from '../pages/PushMessage/';
import Ratings from '../pages/Ratings';
import StatisticPage from '../pages/StatisticPage';
import NewsPage from '../pages/NewsPage';
import AddNews from '../containers/AddNews';
import EditNews from '../containers/EditNews';
import GoodPage from '../pages/GoodPage';
import DocumentationPage from '../pages/DocumentationPage';
import AddDocumentation from '../containers/documentation/AddDocumentation';
import EditDocumentation from '../containers/documentation/EditDocumentation';
import SlidersPage from '../pages/SlidersPage';
import YooKassaPage from '../pages/YooKassaPage';
import ListGoodPage from '../pages/goods/ListGoodPage';
import DetailGoodPage from '../pages/goods/DetailGoodPage';
import EditGoodPage from '../pages/goods/EditGoodPage';
import AddGoodPage from '../pages/goods/AddGoodPage';
import BlockedIP from '../pages/BlockedIP';
import NewOrder from '../components/AcceptOrder/NewOrder';
import PaymentsList from '../components/handbook/PaymentsList';
import AddPayment from '../components/handbook/AddPayment';
import EditPayment from '../components/handbook/EditPayment';
import ClientsPage from '../pages/ClientsPage';
import ClientsList from '../components/user/clients/ClientsList';
import DetailClient from '../components/user/clients/DetailClient';
import EditClient from '../components/user/clients/EditClient';
import UsersPage from '../pages/UsersPage';
import UsersList from '../components/user/UsersList';
import AddUser from '../components/user/AddUser';
import { roleId } from './app';
import MarketingPage from '../pages/MarketingPage';

export const handbookPages = [
    { name: 'cities', title: 'Города', path: '/handbook/cities', component: CityList, fit: true, access: [roleId.admin] },
    { name: 'addCity', title: 'Добавление города', path: '/handbook/cities/add', component: AddCity, fit: true, disableSidebar: true, access: [roleId.admin] },
    { name: 'editCity', title: 'Редактирование города', path: '/handbook/cities/edit/:id', component: EditCity, fit: true, disableSidebar: true, access: [roleId.admin] },
    { name: 'filials', title: 'Филиалы', path: '/handbook/filials', component: FilialList, fit: true, access: [roleId.admin, roleId.coordinator, roleId.cashier, roleId.manager, roleId.regionalDirector] },
    { name: 'addFilial', title: 'Добавление филиала', path: '/handbook/filials/add', component: AddFilial, fit: true, disableSidebar: true, access: [roleId.admin] },
    { name: 'addZone', title: 'Добавление зоны', path: '/handbook/zone/add', component: AddZone, fit: true, disableSidebar: true, access: [roleId.admin] },
    { name: 'editFilial', title: 'Редактирование филиала', path: '/handbook/filials/edit/:id', component: EditFilial, fit: true, disableSidebar: true, access: [roleId.admin, roleId.coordinator, roleId.cashier, roleId.manager, roleId.regionalDirector] },
    { name: 'editZone', title: 'Редактирование зоны', path: '/handbook/zone/edit/:id', component: EditZone, fit: true, disableSidebar: true, access: [roleId.admin] },
    { name: 'viewCity', title: 'Город', path: '/handbook/cities/:id', component: DetailCity, fit: true, disableSidebar: true, access: [roleId.admin] },
    { name: 'viewFilial', title: 'Филиал', path: '/handbook/filials/:id', component: DetailFilial, fit: true, disableSidebar: true, access: [roleId.admin, roleId.coordinator, roleId.manager] },
    { name: 'payments', title: 'Способы оплаты', path: '/handbook/payments', component: PaymentsList, fit: true, access: [roleId.admin] },
    { name: 'addPayment', title: 'Добавление способа оплаты', path: '/handbook/payments/add', component: AddPayment, fit: true, disableSidebar: true, access: [roleId.admin] },
    { name: 'editPayment', title: 'Редактирование способа оплаты', path: '/handbook/payments/edit/:id', component: EditPayment, fit: true, disableSidebar: true, access: [roleId.admin] },
];

export const marketingPages = [
    { name: 'news', title: 'Новости', path: '/marketing/news', component: NewsPage, access: [roleId.admin,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist], exact: true },
    { name: 'addNews', title: 'Добавление новости', path: '/marketing/addNews', disableSidebar: true, component: AddNews, access: [roleId.admin, roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'editNews', title: 'Редактирование', path: '/marketing/news/:id', disableSidebar: true, component: EditNews, access: [roleId.admin, roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'sliders', title: 'Слайдеры', path: '/marketing/sliders', component: SlidersPage, access: [roleId.admin,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'pushMessage', title: 'Push сообщения', path: '/marketing/pushmessage', component: PushMessage, access: [roleId.admin,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'promocodes', title: 'Промокоды', path: '/marketing/promocodes', component: PromocodesPage, access: [roleId.admin,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'ratings', title: 'Оценки пользователей',  path: '/marketing/ratings', component: Ratings, access: [roleId.admin,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
]

export const goodPages = [
    { name: 'list', title: 'Товары', path: '/good', component: ListGoodPage, fit: true, access: [roleId.admin, roleId.chiefCommoditySpecialist,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'detail', title: 'Товар', path: '/good/:id', component: DetailGoodPage, fit: true, access: [roleId.admin, roleId.chiefCommoditySpecialist,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'edit', title: 'Редактирование товара', path: '/good/edit/:id', component: EditGoodPage, fit: true, access: [roleId.admin, roleId.chiefCommoditySpecialist,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'add', title: 'Добавление товара', path: '/good/add', component: AddGoodPage, fit: true, access: [roleId.admin, roleId.chiefCommoditySpecialist,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
];

export const pages = [
    { name: 'dashboard', title: 'Панель управления', path: '/', component: Dashboard },
    { name: 'clients', title: 'Клиенты', path: '/clients', component: ClientsPage, fit: true, access: [roleId.manager, roleId.regionalDirector, roleId.admin, roleId.coordinator, roleId.operator, roleId.cashier, roleId.manager, roleId.regionalDirector,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist], showOnDashboard: true },
    { name: 'users', title: 'Пользователи', path: '/users', component: UsersPage, fit: true, howOnDashboard: true, access: [roleId.admin] },
    { name: 'neworder', title: 'Новый заказ', path: '/orders/new', component: NewOrder, access: [roleId.manager, roleId.regionalDirector, roleId.admin, roleId.coordinator, roleId.operator, roleId.cashier, roleId.manager, roleId.regionalDirector], showOnDashboard: true },
    { name: 'orders', title: 'Заказы', path: '/orders', component: OrdersPage, access: [roleId.manager, roleId.regionalDirector, roleId.admin, roleId.coordinator, roleId.operator, roleId.cashier, roleId.manager, roleId.regionalDirector], showOnDashboard: true },
    { name: 'archive', title: 'Архив заказов', path: '/archive', component: OrdersPage, access: [roleId.manager, roleId.regionalDirector, roleId.admin, roleId.coordinator, roleId.operator, roleId.cashier, roleId.manager, roleId.regionalDirector], showOnDashboard: true },
    // { name: 'goods', title: 'Товары', path: '/goods', component: GoodsPage, access: [roleId.admin, roleId.chiefCommoditySpecialist,  roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'good', title: 'Товары', path: '/good', component: GoodPage, access: [roleId.admin, roleId.chiefCommoditySpecialist, roleId.marketingSpecialist, roleId.chiefMarketingSpecialist], showOnDashboard: true },
    { name: 'handbook', title: 'Справочники',  path: '/handbook', component: HandbookPage, sub: handbookPages, access: [roleId.admin, roleId.coordinator, roleId.cashier, roleId.manager, roleId.regionalDirector] },
    { name: 'marketing', title: 'Маркетинг',  path: '/marketing', component: MarketingPage, sub: marketingPages, access: [roleId.admin, roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'statistic', title: 'Статистика', path: '/statistic', component: StatisticPage, access: [roleId.admin, roleId.chiefAccountant, roleId.marketingSpecialist, roleId.chiefMarketingSpecialist] },
    { name: 'yookassa', title: 'ЮКасса', path: '/yookassa', component: YooKassaPage, access: [roleId.admin, roleId.chiefAccountant] },
    { name: 'settings', title: 'Настройки', path: '/settings', component: Settings, access: [roleId.admin], showOnDashboard: true },
    { name: 'documentation', title: 'Документы', path: '/documentation', component: DocumentationPage, exact: true, access: [roleId.manager, roleId.regionalDirector, roleId.admin], showOnDashboard: true },
    { name: 'addDocumentation', title: 'Добавление документа', path: '/addDocumentation', disableSidebar: true, component: AddDocumentation, access: [roleId.admin] },
    { name: 'editDocumentation', title: 'Редактирование', path: '/documentation/:id', disableSidebar: true, component: EditDocumentation, access: [roleId.admin] },
    { name: 'blockedIp', title: 'Блокировка IP', path: '/blockedip', component: BlockedIP, access: [roleId.admin] },
];

export const clientPages = [
    { name: 'list', title: 'Клиенты', path: '/clients', component: ClientsList, fit: true },
    { name: 'view', title: 'Детальная информация', path: '/clients/:id', component: DetailClient },
    { name: 'edit', title: 'Редактирование клиента', path: '/clients/edit/:id', component: EditClient, access: [roleId.admin] },
];

export const userPages = [
    { name: 'list', title: 'Пользователи', path: '/users', component: UsersList, fit: true, access: [roleId.admin]  },
    { name: 'add', title: 'Создание аккаунта', path: '/users/add', component: AddUser, access: [roleId.admin] },
    { name: 'edit', title: 'Редактирование пользователя', path: '/users/edit/:id', component: AddUser, access: [roleId.admin] }
];

export const ordersPages = [
    { name: 'list', title: 'Заказы', path: '/orders', component: OrdersList, fit: true },
    { name: 'view', title: 'Редактирование заказа', path: '/orders/edit/:id', component: NewOrder, access: [roleId.manager, roleId.regionalDirector, roleId.admin, roleId.coordinator, roleId.operator, roleId.cashier, roleId.manager, roleId.regionalDirector] },
    { name: 'archive', title: 'Архив', path: '/archive', component: OrdersList },
    { name: 'viewArchive', title: 'Редактирование заказа', path: '/archive/edit/:id', component: NewOrder, access: [roleId.manager, roleId.regionalDirector, roleId.admin, roleId.coordinator, roleId.operator, roleId.cashier, roleId.manager, roleId.regionalDirector] },
];