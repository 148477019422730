import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../utils/Input';
import { setPaymentEdit, fetchPayment, updatePayment } from '../../action/handbook';
import cn from 'classnames';
import Loader from '../utils/Loader';
import ErrorContainer from '../utils/ErrorContainer';
import Switch from '../utils/Switch';
import { setUIvisible } from '../../action/UImobile';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class EditPayment extends Component {
    componentWillUnmount() {
        this.props.setPaymentEdit(null);
    }

    componentDidMount() {
        this.props.fetchPayment(this.props.match.params.id).then(payment => {
            this.props.setPaymentEdit(payment);
        });
        this.props.setUIvisible(false, false);
    }
    
    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">{this.props.edit.VisibleName ? this.props.edit.VisibleName : 'Введите название способа оплаты *'}</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push('/handbook/payments')} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    isValidPayment() {
        const { Name, VisibleName } = this.props.edit;
        if (Name && VisibleName) return true;
        
        return false;
    }

    setVisibleClient = (value) => {
        this.setPayment('VisibleClient')(value);
    }

    setPayment = (field) => (e) => {        
        const data = {...this.props.edit};
        if(field !== 'VisibleClient')
            data[field] = e.target.value;
        if(field === 'VisibleClient')
            data[field] = e;
        this.props.setPaymentEdit(data);
    }

    onSubmit = (e) => {
        e.preventDefault();
        const edited = { ...this.props.edit };
        this.props.updatePayment(this.props.match.params.id, edited).then(result => {
            if (result) {
                this.props.history.push('/handbook/payments');
            }
        });        
    }

    render() {
        const { loading, edit } = this.props;
        if (loading || !edit) {
            return (
                <div style={styles.loading}><Loader/></div>
            );
        }

        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <form onSubmit={this.onSubmit} className="kt-form">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Название"
                                        required={true}
                                        onChange={this.setPayment('VisibleName')}
                                        value={edit.VisibleName}
                                        placeholder="Название..."
                                    />
                                    <Input
                                        label="Обозначение"
                                        required={true}
                                        onChange={this.setPayment('Name')}
                                        value={edit.Name}
                                        placeholder="Обозначение..."
                                    />  
                                </div>
                                <div className="col-md-6">
                                    <label className="col-10 col-form-label">Виден клиентам</label>
                                    <div className="col-2">
                                        <Switch
                                            onChange={(value) => this.setVisibleClient(value)} 
                                            checked={edit.VisibleClient} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet">
                        <ErrorContainer field="editTag" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <button
                                    disabled={!this.isValidPayment() || this.props.loading}
                                    type="submit"
                                    className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': this.props.editCityLoader })}>
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    
                </div>
                </form>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    edit: state.handbook.payment.edit,
    loading: state.loaders.payments,
    editCityLoader: state.loaders.editPayment
});

const mapDispatchToProps = {
    setPaymentEdit: (data) => setPaymentEdit(data),
    fetchPayment: (id) => fetchPayment(id),
    updatePayment: (id, data) => updatePayment(id, data),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPayment);