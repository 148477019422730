import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchUsers, setUsersPaging, deleteUser, setUsersFilters } from '../../../action/user';
import { fetchCities, fetchFilials } from '../../../action/handbook';
import Loader from '../../utils/Loader';
import Table from '../../utils/Table/Table';
import Portlet from '../../utils/Portlet';
import ReactPaginate from 'react-paginate';
import ErrorContainer from '../../utils/ErrorContainer';
import swal from 'sweetalert';
import NewMessage from '../../../pages/PushMessage/NewMessage';
import Select from 'react-select';
import Input from '../../utils/Input'
import DateInput from '../../utils/DateInput';
import moment from 'moment';
import { setUIvisible } from '../../../action/UImobile';


const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class ClientsList extends Component {
    state = {
        currentPage: 0,
        userMessage: null
    }

    componentDidMount() {
        this.props.setUIvisible(true, false);
        this.props.fetchUsers();
        this.props.fetchCities();
        this.props.fetchFilials(null);
    }

    componentWillUnmount() {
        const { paging } = this.props;
        this.props.setUsersPaging({ ...paging, start: 0 });
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setUsersPaging(updatedPaging);
        this.props.fetchUsers();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setUsersPaging(updatedPaging);
            this.props.fetchUsers();
        });
    };

    deleteUser = (id) => {
        swal({
            title: "Удаление клиента",
            text: "Вы действительно хотите удалить клиента?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                this.props.deleteUser(id)
            }
        });
    }

    chekMarginPagesDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 400)
            return 1;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        if(userDisplayWidth < 1250)
            return 5;

        return 8;
    }

    chekPageRangeDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        return 5;
    }

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={this.chekMarginPagesDisplayed()}
                    pageRangeDisplayed={this.chekPageRangeDisplayed()}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    renderAdress(item) {
        return `${item.city.name}, ${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    setUsersFilters = (field) => (data) => {
        const { filters } = this.props;
        const updated = {
            ...filters,
            [field]: data
        }
        if (field === 'city') {
            updated.point = null;
            this.props.fetchFilials(data.value)
        }
        this.props.setUsersFilters(updated);
        this.props.fetchUsers();
    }

    onChangeInput = (field) => (e) => {
        const value = e.target.value;

        const { filters } = this.props;
        const updated = {
            ...filters,
            [field]: value
        }
        this.props.setUsersFilters(updated);
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props;

        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }

        this.props.setUsersFilters(updated);
    }

    resetFilter = () => {
        this.props.setUsersFilters({ city: null, point: null });
        this.setState({ currentPage: 0 });
        this.props.fetchUsers();
    }

    onSearch = () => {
        const { paging } = this.props;
        this.props.setUsersPaging({ ...paging, start: 0 });
        this.setState({ currentPage: 0 });
        this.props.fetchUsers();
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.onSearch(); 
        }
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Клиенты</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {this.props.count}
                        </span>
                    </div>
                </div>        
               
            </div>
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }

        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <div className="kt-portlet__body">
                        <div className="kt-form kt-form--label-right kt-margin-b-10">
                            {(window.innerWidth > 1024 || this.props.UImobile.openFilters) && <div className="filterBlocks" onKeyDown={this.onKeyDown}>
                                {window.innerWidth > 400 && <div className="dateBlock">
                                    <DateInput
                                        placeholderText="Дата от"
                                        selected={this.props.filters.createdStartDate}
                                        onChange={this.onChangeDateInput('createdStartDate')}
                                        maxDate={moment().toDate()}
                                    />
                                    <div>-</div>
                                    <DateInput
                                        placeholderText="Дата до"
                                        selected={this.props.filters.createdEndDate}
                                        onChange={this.onChangeDateInput('createdEndDate')}
                                        maxDate={moment(this.props.filters.createdStartDate).add({ days: 30 }).toDate()}
                                        required={this.props.filters.createdStartDate}
                                    />
                                </div>}
                                <div>
                                    <Input placeholder="Телефон" onChange={this.onChangeInput('phone')} disableGroup={true} value={this.props.filters.phone}/>
                                </div>
                                <div>
                                    <Input placeholder="Имя" onChange={this.onChangeInput('name')} disableGroup={true} value={this.props.filters.name}/>
                                </div>
                                <div style={{ width: 300 }}>
                                    <Select
                                        isLoading={this.props.loadingCity}
                                        isDisabled={this.props.loadingCity}
                                        value={this.props.filters.city}
                                        closeMenuOnSelect={true}
                                        onChange={this.setUsersFilters('city')}
                                        options={this.props.cities.map(item => ({
                                            value: item.id,
                                            label: item.name
                                        }))}
                                        placeholder="Город"
                                        noOptionsMessage={() => 'Нет городов'}
                                    />
                                </div>
                                <div style={{ width: 300 }}>
                                    <Select
                                        isLoading={this.props.loadingFilials}
                                        isDisabled={this.props.loadingFilials}
                                        value={this.props.filters.point}
                                        closeMenuOnSelect={true}
                                        onChange={this.setUsersFilters('point')}
                                        options={this.props.filials.map(item => ({
                                            value: item.id,
                                            label: this.renderAdress(item)
                                        }))}
                                        placeholder="Пункт самовывоза"
                                        noOptionsMessage={() => 'Нет городов'}
                                    />
                                </div>
                                <div>
                                    <button style={{ marginRight: 5 }} onClick={this.onSearch} className="btn btn-primary">Найти</button>
                                    <button onClick={this.resetFilter} className="btn btn-danger">Сбросить</button>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <Portlet fit={true}>
                        <ErrorContainer field="users" style={{ margin: 15 }} hasCloseButton={true}/>
                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                            <Table role={this.props.role} onUserMessage={(item) => this.setState({ userMessage: item })} onDelete={this.deleteUser} items={this.props.users}/>
                            {
                                this.props.users.length > 0 && this.renderPagination()
                            }
                            {this.state.userMessage && <NewMessage user={this.state.userMessage} onClose={() => this.setState({ userMessage: null })}/>}
                        </div>
                    </Portlet>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users.list,
    filters: state.users.filters,
    loading: state.loaders.users,
    count: state.users.count,
    paging: state.users.paging,
    cities: state.handbook.cities,
    loadingCity: state.loaders.cities,
    filials: state.handbook.filials,
    loadingFilials: state.loaders.filials,
    role: state.user.info.role.id,
    UImobile: state.UImobile
});

const mapDispatchToProps = {
    fetchUsers: () => fetchUsers('clients'),
    setUsersPaging: (paging) => setUsersPaging(paging),
    deleteUser: (id) => deleteUser(id, 'clients'),
    fetchCities: () => fetchCities(true),
    fetchFilials: (city) => fetchFilials(city, true),
    setUsersFilters: (filters) => setUsersFilters(filters),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);