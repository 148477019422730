import React, { Component } from "react";
import { connect } from "react-redux";
import { addPositionNewOrder } from "../../action/neworder";


class Goods extends Component {
    render() {
        return(
            <div className="neworder_good_button_container">
                {
                    this.props.loaderList
                        ? null
                        : this.props.goods.map((item) => 
                        <button 
                        key={item.id} 
                        className={`neworder_good_button${item.category.id === this.props.settings.pizza ? ' pizza' : ''}${(!this.props.neworders.point || (this.props.neworders.point.stopList ? this.props.neworders.point.stopList : []).some((el) => el.value === item.id)) ? ' unactived' : ''}`} 
                        onClick={(() => {
                            if(this.props.neworders.point && !(this.props.neworders.point.stopList ? this.props.neworders.point.stopList : []).some((el) => el.value === item.id)) {
                                this.props.addPositionNewOrder(item, this.props.settings);

                                if((window.innerWidth < 930 && window.innerWidth > 640) || (window.innerWidth < 1130 && this.props.UImobile && this.props.UImobile.openSidebar && window.innerWidth > 640))
                                    this.props.setActiveTabMobile('positionList');
                            }                                
                        })}
                        >
                            {item.hot && <div className="good_hot_circle"></div>}
                            <span className={`neworder_good_button_text${item.category.id === this.props.settings.pizza ? ' pizza' : ''}`}> {item.name} </span>
                            {!this.props.neworders.isAggPrice 
                            ?
                            <span className="neworder_good_button_text price"> {`${item.price} ₽`} </span>
                            :
                            <span className="neworder_good_button_text price"> {`${item.oldPrice} ₽`} </span>
                            }
                            {item.category.id === this.props.settings.pizza && 
                            <div className="neworder_pizza_modification_button_div">
                                <button 
                                    className={`neworder_pizza_modification_button ${!item.isMod ? 'actived' : ''}`} 
                                    onClick={((event) => {
                                        event.stopPropagation();
                                        this.props.changeModification(item.id, item.modifications.find((mod) => mod.default))
                                    })}
                                    > 
                                    30см
                                </button>
                                <button 
                                    className={`neworder_pizza_modification_button ${item.isMod ? 'actived' : ''}`} 
                                    onClick={((event) => {
                                        event.stopPropagation();
                                        this.props.changeModification(item.id, item.modifications.find((mod) => !mod.default))
                                    })}
                                    > 
                                    36см
                                </button>
                            </div>
                            }
                        </button>)
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    neworders: state.neworders,
    settings: state.settings.init.categories
});

const mapDispatchToProps = {
    addPositionNewOrder: (good, settings) => addPositionNewOrder(good, settings)
}

export default connect(mapStateToProps, mapDispatchToProps)(Goods);