import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchCities, setHandbookPaging, deleteCity } from '../../action/handbook';
import Loader from '../utils/Loader';
import TableCities from './TableCities';
import Portlet from '../utils/Portlet';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';
import ErrorContainer from '../utils/ErrorContainer';
import { roleId } from '../../constants/app';
import HeadMobileActionMenu from '../utils/HeadMobileActionMenu';
import { setUIvisible } from '../../action/UImobile';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class CityList extends Component {
    componentDidMount() {
        this.props.fetchCities();
        this.props.setUIvisible(false, true)
    }

    state = {
        currentPage: 0
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setHandbookPaging('city', updatedPaging);
        this.props.fetchCities();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setHandbookPaging('city', updatedPaging);
            this.props.fetchCities();
        });
    };

    chekMarginPagesDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 400)
            return 1;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        if(userDisplayWidth < 1250)
            return 5;

        return 8;
    }

    chekPageRangeDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        return 5;
    }

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={this.chekMarginPagesDisplayed()}
                    pageRangeDisplayed={this.chekPageRangeDisplayed()}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Города</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {this.props.count} Всего
                        </span>
                       
                    </div>
                </div>
                {
                    this.props.role === roleId.admin && window.innerWidth > 1024 && (
                        <div className="kt-subheader__toolbar">
                            <Link to="/handbook/cities/add" className="btn btn-label-brand btn-bold">
                                Добавить город
                            </Link>
                        </div>
                    )
                }
                
            </div>
            </div>
        );
    }

    deleteItem = (city) => {
        swal({
            title: "Удаление города",
            text: "Вы действительно хотите удалить город?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                this.props.deleteCity(city)
            }
        });
    }

    render() {
        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }

        const actionsMobile =[
            { access: [roleId.admin], name: 'Добавить город', icon: 'flaticon2-plus', action: () => this.props.history.push('/handbook/cities/add')}
        ].filter(item => !item.access || item.access.includes(this.props.role));
        
        return (
            <Fragment>
                <HeadMobileActionMenu actions={actionsMobile}/>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <Portlet fit={true}>
                    <ErrorContainer field="cities" style={{ margin: 15 }} hasCloseButton={true}/>
                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                        <TableCities deleteItem={this.deleteItem} role={this.props.role} items={this.props.cities}/>
                        {
                            this.props.cities.length > 0 && this.renderPagination()
                        }
                    </div>
                </Portlet>
            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    cities: state.handbook.cities,
    loading: state.loaders.cities,
    paging: state.handbook.paging.city,
    count: state.handbook.count.cities,
    role: state.user.info.role.id
});

const mapDispatchToProps = {
    fetchCities: () => fetchCities(),
    setHandbookPaging: (field, paging) => setHandbookPaging(field, paging),
    deleteCity: (city) => deleteCity(city),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(CityList);