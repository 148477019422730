const initialState = {
    openFilters: false,
    openAction: false,
    openSidebar: false,
    visibleFiltersButton: true,
    visibleActionButton: true
};

export default function UImobile(state = initialState, action) {
  switch(action.type) {
        case 'SET_UI': {
            return {
                ...state,
                [action.field]: !state[action.field]
            };
        }
        case 'SET_UI_SIDEBAR': {
            return {
                ...state,
                openSidebar: action.value
            };
        }
        case 'SET_UI_VISIBLE': {
            return {
                ...state,
                visibleFiltersButton: action.visibleFiltersButton,
                visibleActionButton: action.visibleActionButton
            };
        }
        case 'SET_UI_DEFAULT': {
            return {
                ...state,
                openFilters: false,
                openAction: false
            }
        }
        default: return state;
    }
}