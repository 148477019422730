import { createAction } from 'redux-actions';
import { urls } from '../constants/app';
import { makeActionType } from '../utils';
import { createFormData } from '../utils/common';
import { resetError, setError } from './errors';
import request from './utils';

const namespace = 'GOODS';

export const setGoods = createAction(makeActionType(namespace, ['SET']));
export const setGood = createAction(makeActionType(namespace, ['SET', 'GOOD']));
export const addGoodList = createAction(makeActionType(namespace, ['ADD', 'GOOD']));
export const deleteGoodAction = createAction(makeActionType(namespace, ['DELETE', 'GOOD']));
export const setCategories = createAction(makeActionType(namespace, ['SET', 'CATEGORIES']));
export const setPagingGoods = createAction(makeActionType(namespace, ['SET', 'PAGING']));
export const setSortingGoods = createAction(makeActionType(namespace, ['SET', 'SORTING']));
export const setFiltersGoods = createAction(makeActionType(namespace, ['SET', 'FILTERS']));
export const setCountGoods = createAction(makeActionType(namespace, ['SET', 'COUNT']));
export const setDetailGood = createAction(makeActionType(namespace, ['SET', 'DETAIL']));
export const setActiveCategory = createAction(makeActionType(namespace, ['SET', 'ACTIVE_CATEGORY']));
export const setActiveModalAction = createAction(makeActionType('LAYOUT', ['SET', 'MODAL']));

export function fetchGoods(withoutLoader = false) {
    return async (dispatch) => {
        !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'goods', value: true });
        dispatch(resetError('goods'));
        const params = { withoutFilterOnTime: true };
        try {
            const goods = await request({ method: 'get', url: urls.goods, params });
            dispatch(setGoods(goods))
            dispatch(setCountGoods(goods.length));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'goods', value: false });
        } catch(error) {
            dispatch(setError(error, 'goods'));
            !withoutLoader && dispatch({ type: 'SET_LOADER', field: 'goods', value: false });
        }
    }
}

export function fetchGood(id) {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'detailGood', value: true });
        dispatch(resetError('detailGood'));
        try {
            const list = getState().good.list;
            const itemGood = list.find((item) => item.id === +id);

            if (itemGood) {
                dispatch(setDetailGood(itemGood));
                dispatch({ type: 'SET_LOADER', field: 'detailGood', value: false });
            } else {
                const good = await request({ method: 'get', url: `${urls.goods}/${id}` });
                dispatch(setDetailGood(good));
                dispatch({ type: 'SET_LOADER', field: 'detailGood', value: false });
            }
        } catch(error) {
            dispatch(setError(error, 'detailGood'));
            dispatch({ type: 'SET_LOADER', field: 'detailGood', value: false });
        }
    }
}

export function addGood(values) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'modalGood', value: true });
        const data = createFormData(values);
        try {
            const good = await request({ method: 'post', url: urls.goods, data, headers: { "Content-Type": "multipart/form-data" }, });
            if (good) {
                dispatch({ type: 'SET_LOADER', field: 'modalGood', value: false });
                dispatch(addGoodList(good));
                return good.id;
            }
        } catch(error) {
            dispatch(setError({ status: 506 }, 'modalGood'));
            dispatch({ type: 'SET_LOADER', field: 'modalGood', value: false });
            return null;
        }
    }
}

export function updateGood(id, values) {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'updateGood', value: id });
        dispatch(resetError('goods'));
        const data = createFormData(values);

        try {
            const detail = getState().good.detail;
            const good = await request({ method: 'put', url: `${urls.goods}/${id}`, data, headers: { "Content-Type": "multipart/form-data" }, });
            dispatch(setGood(good));
            if (detail) {
                dispatch(setDetailGood(good));
            }
            dispatch({ type: 'SET_LOADER', field: 'updateGood', value: null });
            return good.id;
        } catch(error) {
            dispatch(setError(error, 'goods'));
            dispatch({ type: 'SET_LOADER', field: 'updateGood', value: null });
            return null;
        }
    }
}

export function updateGoodsPrices(data) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'updateGoods', value: true });
        try {
            const good = await request({ method: 'put', url: `${urls.goods}/updateGoodsPrices`, data })
            dispatch({ type: 'SET_LOADER', field: 'updateGoods', value: null });
            return good;
        } catch(error) {
            dispatch(setError(error, 'goods'));
            dispatch({ type: 'SET_LOADER', field: 'updateGoods', value: null });
            return null;
        }
    }
}

export function deleteGood(id) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'deleteGood', value: id });
        dispatch(resetError('goods'));
        try {
            await request({ method: 'delete', url: `${urls.goods}/${id}` });
            dispatch(deleteGoodAction(id));
            dispatch({ type: 'SET_LOADER', field: 'deleteGood', value: null });
        } catch(error) {
            dispatch(setError({ status: 507 }, 'goods'));
            dispatch({ type: 'SET_LOADER', field: 'deleteGood', value: null });
        }
    }
}

export function fetchCategories() {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'category', value: true });
        dispatch(resetError('category'));
        try {
            const activeCategory = getState().good.activeCategory;
            const items = await request({ method: 'get', url: urls.categories, params: { '_sort': 'priority:DESC' } });
            dispatch(setCategories(items));
            if (!activeCategory) {
                dispatch(setActiveCategory(items[0] ? items[0].id : null))
            }
            dispatch({ type: 'SET_LOADER', field: 'category', value: false });
        } catch(error) {
            dispatch(setError(error, 'category'));
            dispatch({ type: 'SET_LOADER', field: 'category', value: false });
        }
    }
}

export function addCategory(values) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'modalCategory', value: true });
        const data = createFormData(values);

        try {
            const result = await request({ method: 'post', url: urls.categories, data, headers: { "Content-Type": "multipart/form-data" }, });
            if (result) {
                dispatch({ type: 'SET_LOADER', field: 'modalCategory', value: false });
                dispatch(fetchCategories());
                return true;
            }
        } catch(error) {
            dispatch(setError({ status: 506 }, 'modalCategory'));
            dispatch({ type: 'SET_LOADER', field: 'modalCategory', value: false });
            return false;
        }
    }
}

export function updateCategory(id, values) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'modalCategory', value: true });
        const data = createFormData(values);

        try {
            const result = await request({ method: 'put', url: `${urls.categories}/${id}`, data, headers: { "Content-Type": "multipart/form-data" }, });
            if (result) {
                dispatch({ type: 'SET_LOADER', field: 'modalCategory', value: false });
                dispatch(fetchCategories());
                dispatch(fetchGoods());
                return true;
            }
        } catch(error) {
            dispatch(setError({ status: 506 }, 'modalCategory'));
            dispatch({ type: 'SET_LOADER', field: 'modalCategory', value: false });
            return false;
        }
    }
}

export function deleteCategory(id) {
    return async (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'modalCategory', value: true });
        try {
            await request({ method: 'delete', url: `${urls.categories}/${id}` });
            dispatch({ type: 'SET_LOADER', field: 'modalCategory', value: false });
            dispatch(fetchCategories());
            dispatch(setActiveModalAction({ field: 'category', value: null }))
        } catch(error) {
            dispatch(setError({ status: 507 }, 'modalCategory'));
            dispatch({ type: 'SET_LOADER', field: 'modalCategory', value: false });
        }
    }
}

export function addRegionPrice(data) {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'modalRegionPrice', value: true });
        dispatch(resetError('modalRegionPrice'));
        try {
            const detail = getState().good.detail;
            const good = await request({ method: 'post', url: urls.regionprices, data });
            dispatch(setGood(good));
            if (detail) {
                dispatch(setDetailGood(good));
            }
            dispatch({ type: 'SET_LOADER', field: 'modalRegionPrice', value: false });
            return good.id;
        } catch(error) {
            dispatch(setError({ status: 506 }, 'modalRegionPrice'));
            dispatch({ type: 'SET_LOADER', field: 'modalRegionPrice', value: false });
            return null;
        }
    }
}

export function updateRegionPrice(id, data) {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'updateRegionPrice', value: id });
        dispatch(resetError('detailGood'));
        try {
            const detail = getState().good.detail;
            const good = await request({ method: 'put', url: `${urls.regionprices}/${id}`, data });
            dispatch(setGood(good));
            if (detail) {
                dispatch(setDetailGood(good));
            }
            dispatch({ type: 'SET_LOADER', field: 'updateRegionPrice', value: null });
            return good.id;
        } catch(error) {
            dispatch(setError({ status: 506 }, 'detailGood'));
            dispatch({ type: 'SET_LOADER', field: 'updateRegionPrice', value: null });
            return null;
        }
    }
}

export function deleteRegionPrice(id, goodId) {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'deleteRegionPrice', value: id });
        dispatch(resetError('detailGood'));
        try {
            const detail = getState().good.detail;
            const good = await request({ method: 'delete', url: `${urls.regionprices}/${id}`, params: { goodId } });
            dispatch(setGood(good));
            if (detail) {
                dispatch(setDetailGood(good));
            }
            dispatch({ type: 'SET_LOADER', field: 'deleteRegionPrice', value: null });
            return good.id;
        } catch(error) {
            dispatch(setError({ status: 506 }, 'detailGood'));
            dispatch({ type: 'SET_LOADER', field: 'deleteRegionPrice', value: null });
            return null;
        }
    }
}


export function addModification(data) {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'modalModification', value: true });
        dispatch(resetError('modalModification'));
        try {
            const detail = getState().good.detail;
            const good = await request({ method: 'post', url: urls.modifications, data });
            dispatch(setGood(good));
            if (detail) {
                dispatch(setDetailGood(good));
            }
            dispatch({ type: 'SET_LOADER', field: 'modalModification', value: false });
            return good.id;
        } catch(error) {
            dispatch(setError({ status: 506 }, 'modalModification'));
            dispatch({ type: 'SET_LOADER', field: 'modalModification', value: false });
            return null;
        }
    }
}

export function updateModification(id, data) {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'updateModification', value: id });
        dispatch(resetError('detailGood'));
        dispatch(resetError('modalModification'));
        try {
            const detail = getState().good.detail;
            const good = await request({ method: 'put', url: `${urls.modifications}/${id}`, data });
            dispatch(setGood(good));
            if (detail) {
                dispatch(setDetailGood(good));
            }
            dispatch({ type: 'SET_LOADER', field: 'updateModification', value: null });
            return good.id;
        } catch(error) {
            dispatch(setError({ status: 506 }, 'detailGood'));
            dispatch(setError({ status: 506 }, 'modalModification'));
            dispatch({ type: 'SET_LOADER', field: 'updateModification', value: null });
            return null;
        }
    }
}

export function deleteModification(id, goodId) {
    return async (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'deleteModification', value: id });
        dispatch(resetError('detailGood'));
        try {
            const detail = getState().good.detail;
            const good = await request({ method: 'delete', url: `${urls.modifications}/${id}`, params: { goodId } });
            dispatch(setGood(good));
            if (detail) {
                dispatch(setDetailGood(good));
            }
            dispatch({ type: 'SET_LOADER', field: 'deleteModification', value: null });
            return good.id;
        } catch(error) {
            dispatch(setError({ status: 506 }, 'detailGood'));
            dispatch({ type: 'SET_LOADER', field: 'deleteModification', value: null });
            return null;
        }
    }
}