import React, {Component} from 'react';
import Sidebar from '../components/layout/Sidebar';
import {Route, Redirect} from "react-router-dom";
import { pages } from '../constants/page';
import { connect } from 'react-redux';
import { getCurrentUser } from '../action/user';
import LoadingPage from './LoadingPage';
import { Modals } from '../containers/modals/Modals';
import { setUImobile } from '../action/UImobile';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Blackout from '../components/layout/Blackout';

class MainPage extends Component {
  componentDidMount() {
    this.props.getCurrentUser();
  }

  renderError() {
    return (
      <div style={{ width: '100%' }} className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
        <div className="error-page">   
          <div className="alert alert-danger" role="alert">
            <div className="alert-icon"><i className="flaticon-warning"></i></div>
            <div className="alert-text">{this.props.error}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.loading) return <LoadingPage />;
    if (this.props.error)  return this.renderError();

    return (
      <div style={{ width: '100%' }} className="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-subheader--fixed kt-subheader--solid kt-aside--enabled kt-aside--fixed">
        <div className="kt-header-mobile  kt-header-mobile--fixed " style={{ padding: '0px 5px', position: 'fixed', width: '100vw', zIndex: '9999' }}> 
          <div style={{ display: 'flex', gap: '10px'}}>        
            <div className="kt-header-mobile__toolbar">
              <button 
              className={`kt-header-custom-mobile__toggler${this.props.UImobile.openSidebar ? ' active' : ''}`}
              style={{ margin: '0px'}}
              id="kt_header_mobile_toggler"
              onClick={(() => this.props.setUImobile('openSidebar'))}
              >
                <span></span>
              </button>
            </div>
            <div className="kt-header-mobile__logo"><Link to="/"><img style={{height: '25px'}} src='/images/SB_Logo_new_white.png' alt='logo'/></Link></div>
          </div>
          
          <div className="kt-header-mobile__toolbar">
            {this.props.UImobile.visibleFiltersButton && <button
              className={`kt-header-mobile__toggler kt-header-mobile__toggler--left${this.props.UImobile.openFilters ? ' active' : ''}`}
              id="kt_aside_mobile_toggler"
              onClick={(() => this.props.setUImobile('openFilters'))}
              >
              <span></span>
            </button>}
           
            {this.props.UImobile.visibleActionButton && <button
              className={`kt-header-mobile__topbar-toggler${this.props.UImobile.openAction ? ' active' : ''}`}
              id="kt_header_mobile_topbar_toggler"
              onClick={(() => this.props.setUImobile('openAction'))}
              >
              <i className="flaticon-more"></i>
            </button>}
          </div>
        </div>

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <Sidebar user={this.props.user}/>
            <Blackout />
            <div className={`kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-sidebar${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`} id="kt_wrapper">
            
              {
                pages.map(page => {

                  return (
                    <Route
                      key={page.name}
                      exact={page.path === '/' || page.exact}
                      path={page.path}
                      render={props => {
                        if (page.access && !page.access.includes(this.props.user.role.id)) {
                          return <Redirect to={{ pathname: "/" }} />;
                        }
                        const Component = page.component;
                        return <Component {...props}/>
                      }}
                    />
                  )
                }) 
              }            

          </div>
          </div>

          </div>

          <Modals />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loaders.global,
  user: state.user.info,
  error: state.errors.global,
  UImobile: state.UImobile
});

const mapDispatchToProps = {
  getCurrentUser: () => getCurrentUser(),
  setUImobile: (field) => setUImobile(field)
}


export default connect(mapStateToProps, mapDispatchToProps)(MainPage);