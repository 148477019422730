import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { urls } from "../../constants/app";
import request from "../../action/utils";
import { v4 as uuidv4 } from 'uuid';

const SaveNewOrderButton = () => {
    const dispatch = useDispatch();
    const stateNewOrders = useSelector(state => state.neworders);
    const userInfo = useSelector(state => state.user.info);

    const handleClickSave = () => {    
        dispatch({ type: 'SET_LOADER', field: 'loadingSaveOrder', value: true });
        const order = createNewOrder();
        if(window.location.pathname.includes('/edit')) {
            if(stateNewOrders.id) {
                let url = urls.orders
                if(window.location.pathname.includes('/archive'))
                    url = urls.archive
                return request({ method: 'put', url: `${url}/${stateNewOrders.id}`, data: order })
                .then(() => {
                    dispatch({ type: 'CLEAR_NEW_ORDER' });
                    dispatch({ type: 'SET_ORDER', order: null })
                    window.location.href = '/orders';
                    return true;
                })
                .catch(er => {
                    alert('Ошибка ' + er.status);
                    console.log(er);
                    return false;
                })
                .finally(() => {
                    dispatch({ type: 'SET_LOADER', field: 'loadingSaveOrder', value: false });
                });
            } else {
                alert('Ошибка не найден id заказа')
            }
        } else {
            return request({ method: 'post', url: urls.orders, data: order })
            .then(() => {
                dispatch({ type: 'CLEAR_NEW_ORDER' });
                dispatch({ type: 'SET_ORDER', order: null })
                window.location.href = '/orders';
            })
            .catch((er) => {        
                alert('Ошибка ' + er.status);
                console.log(er);
            })
            .finally(() => {
                dispatch({ type: 'SET_LOADER', field: 'loadingSaveOrder', value: false });
            })
        }
    }
    
    const createNewOrder = () => {    
        let checkChangeCash = stateNewOrders.changeCash
        let checkComment = String(stateNewOrders.comment)
        if (stateNewOrders.changeCash && !['no', 'c1000', 'c1500', 'c2000', 'c5000'].includes(stateNewOrders.changeCash))
        {
            checkChangeCash = null
            checkComment = checkComment + String(stateNewOrders.changeCash ? ' | сдача с '+ stateNewOrders.changeCash.slice(1) : '')
        }
    
        const checkGoods = stateNewOrders.goods.map((item) => {
            if(!item.wokData)
                return {
                    id: item.id,
                    article: item.article,
                    isMod: item.isMod ? !item.isMod.default : false,
                    count: item.count,
                    isWok: false,
                    wokData: null,
                    price: stateNewOrders.isAggPrice ? (item.oldPrice ? item.oldPrice : item.price) : item.price,
                    category: item.category.id ? item.category.id : item.category,
                    categoryName: item.categoryName ? item.categoryName : item.category.title ? item.category.title : null,
                    data: {
                        title: (item.data && item.data.title)? item.data.title : item.name ? item.name : null,
                        weight: (item.data && item.data.weight) ? item.data.weight : item.weight ? item.weight : 0,
                    },
                }
    
            const hash = (() => {
                const check = {
                    [item.id]: 1,
                };
                if(item.wokData.souce)
                Object.values(item.wokData.souce).forEach((item) => {
                    check[item.id] = 1;
                });
                if (item.wokData.meat) {
                Object.values(item.wokData.meat).forEach((item) => {
                    check[item.id] = item.count;
                    });
                }
                if (item.wokData.topping) {
                Object.values(item.wokData.topping).forEach((item) => {
                    check[item.id] = item.count;
                    });
                }
                const uuid = uuidv4(JSON.stringify(check));
                return uuid;
                });
                
            const WokDataCheck = {
                ...item.wokData,
                id: hash,
                price: stateNewOrders.isAggPrice ? (item.wokData.oldPrice ? item.wokData.oldPrice : item.wokData.price) : item.wokData.price,
                meat: Object.keys(item.wokData.meat).reduce((acc, key) => {
                    const meatItem = item.wokData.meat[key];
                    acc[key] = {
                      count: meatItem.count,
                      article: meatItem.article,
                      data: meatItem.data
                    };
                    return acc;
                }, {}),
                topping: Object.keys(item.wokData.topping).reduce((acc, key) => {
                    const toppingItem = item.wokData.topping[key];
                    acc[key] = {
                      count: toppingItem.count,
                      article: toppingItem.article,
                      data: toppingItem.data
                    };
                    return acc;
                }, {}),
                souce: Object.keys(item.wokData.souce).reduce((acc, key) => {
                    const souceItem = item.wokData.souce[key];
                    acc[key] = {
                      id: souceItem.id,
                      article: souceItem.article,
                      title: souceItem.title,
                      count: souceItem.count,
                      price: souceItem.price,
                      oldPrice: souceItem.oldPrice
                    };
                    return acc;
                }, {})              
            }
    
            return {
                id: hash,
                isMod: false,
                count: item.count,
                isWok: true,
                wokData: WokDataCheck 
            }
        }) 
    
        const newOrder = {
            deliveryMethod: stateNewOrders.deliveryMethod,
            point: stateNewOrders.point.id,
            city: stateNewOrders.point.city.id,        
            phone: String(stateNewOrders.phone),
            comment: checkComment,
            timeMethod: stateNewOrders.timeMethod ? stateNewOrders.timeMethod : 'fast',
            deliveryPrice: stateNewOrders.deliveryPrice,
            deliveryArticle: stateNewOrders.deliveryArticle,
            deliveryTime: stateNewOrders.deliveryTime ? stateNewOrders.deliveryTime : null,
            deliveryTimeComment: stateNewOrders.deliveryTimeComment ? stateNewOrders.deliveryTimeComment : null,
            totalPrice: parseFloat(stateNewOrders.totalPrice),
            goodsPrice: parseFloat(stateNewOrders.goodPrice),
            salePrice: parseFloat(stateNewOrders.salePrice),
            unit: parseInt(stateNewOrders.unit) ? parseInt(stateNewOrders.unit) : null,
            goods: checkGoods,
            payment: stateNewOrders.payment,
            changeCash: checkChangeCash,
            newApi: true,
            isSite: stateNewOrders.isSite ? stateNewOrders.isSite : false,
            isAggPrice: stateNewOrders.isAggPrice,
            bonusCount: stateNewOrders.bonusCount ? stateNewOrders.bonusCount : 0,
            zone: stateNewOrders.point ? null : stateNewOrders.zone ? stateNewOrders.zone : null,
            user: stateNewOrders.user ? stateNewOrders.user : null,
            userName: stateNewOrders.userName ? stateNewOrders.userName : null,
            source: stateNewOrders.source ? stateNewOrders.source : 'crm',
            visible_datetime: stateNewOrders.visible_datetime
        }

        if(!window.location.pathname.includes('/edit')) {
            newOrder.authorRole = userInfo.role.id;
        }
    
        if(stateNewOrders.deliveryMethod === 'delivery')
        {
            newOrder.street = stateNewOrders.street;
            newOrder.house = stateNewOrders.house;
            newOrder.room = stateNewOrders.room;
            newOrder.entrance = stateNewOrders.entrance;
            newOrder.floor = stateNewOrders.floor;
        }
    
        return newOrder;
    }

    return (
        <button className="order_position_list_footer_button save" onClick={handleClickSave}>Сохранить</button>
    );
}

export default SaveNewOrderButton;


