import { isEmpty } from 'lodash';
import React from 'react'
import { useSelector } from 'react-redux';
import InfoBox from '../../../components/utils/InfoBox';
import { fromStore } from '../../../selectors';
import { MEASURES } from '../constants';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import { deleteModification, setActiveModalAction, updateModification } from '../../../action';
import swal from 'sweetalert';
import { getNewPrice } from '../../../utils/common';

export function ModificationsGood() {
    const dispatch = useDispatch();
    const detail = useSelector(fromStore.detailGoodSelector);
    const loaderUpdate = useSelector(fromStore.loaderUpdateModificationSelector);
    const loaderDelete = useSelector(fromStore.loaderDeleteModificationSelector);

    if (isEmpty(detail.modifications))  {
        return (
            <div style={{ padding: '20px' }}>
                <InfoBox title="Модификации" subtitle="Для данного товара нет модификаций" />
            </div>
        );
    }

    const onOpenModalModification = (id) => (e) => {
        e.stopPropagation();
        dispatch(setActiveModalAction({ field: 'modification', value: id }));
    };

    const onToogleActive = (id, active) => () => {
        const data = { active: !active, goodId: detail.id };
        dispatch(updateModification(id, data))
    };

    const onDelete = (id) => () => {
        swal({
            title: `Удаление модификации`,
            text: `Вы действительно хотите удалить модификацию?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteModification(id, detail.id));
            }
        });
    }

    const header = (
        <thead className="kt-datatable__head">
            <tr className="kt-datatable__row">
                <th width="80px" className="kt-datatable__cell kt-datatable__cell--sort"><span>ID</span></th>
                <th width="145px" className="kt-datatable__cell kt-datatable__cell--sort"><span>Название</span></th>
                <th width="100px" className="kt-datatable__cell kt-datatable__cell--sort"><span>Цена</span></th>
                <th width="100px" className="kt-datatable__cell kt-datatable__cell--sort"><span>Старая цена</span></th>
                <th width="145px" className="kt-datatable__cell kt-datatable__cell--sort"><span>Вес/объем</span></th>
                <th width="145px" className="kt-datatable__cell kt-datatable__cell--sort"><span style={{ textAlign: 'center' }}>По умолчанию</span></th>
                <th width="145px" className="kt-datatable__cell kt-datatable__cell--sort"><span></span></th>
            </tr>
        </thead>
    );

    if(window.innerWidth > 900)      
        return (
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                <table className="kt-datatable__table" style={{ width: '100%' }}>
                    {header}
                    <tbody className="kt-datatable__body ps ps--active-y">
                        {detail.modifications.map((item) => {
                            const isLoadingUpdate = loaderUpdate === item.id;
                            const isLoadingDelete = loaderDelete === item.id;
                            const disabled = isLoadingDelete && isLoadingUpdate;
                            return (
                                <tr key={item.id} className="kt-datatable__row">
                                    <td width="80px" className="kt-datatable__cell">
                                        <span>{item.id}</span>
                                    </td>
                                    <td width="145px" className="kt-datatable__cell">
                                        <span>{item.title}</span>
                                    </td>
                                    <td width="100px" className="kt-datatable__cell"><span>{getNewPrice(detail.price, item.condition, item.price, item.persent)} ₽</span></td>
                                    <td width="100px" className="kt-datatable__cell">
                                        <span>{`${getNewPrice(detail.oldPrice, item.condition, item.oldPrice, item.persent)} ₽`}</span>
                                    </td>
                                    <td width="145px" className="kt-datatable__cell">
                                        <span>{item.weight ? item.weight : detail.weight} {MEASURES[detail.measure]}</span>
                                    </td>
                                    <td width="145px" className="kt-datatable__cell"><span style={{ textAlign: 'center' }}>{item.default ? <span className="kt-badge kt-badge--success kt-badge--dot kt-badge--xl"></span> : ''}</span></td>
                                    <td align='right' width="145px" className="kt-datatable__cell">
                                        <button
                                            disabled={disabled}
                                            style={{ marginRight: '3px' }}
                                            onClick={onToogleActive(item.id, item.active)}
                                            data-tip={item.active ? 'Выключить' : 'Включить'}
                                            className={cn("btn btn-sm btn-icon btn-icon-md", {
                                                'btn-danger': !item.active,
                                                'btn-success': item.active,
                                                'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': isLoadingUpdate
                                            })}>
                                            {!isLoadingUpdate && <i className="la la-power-off"></i>}
                                        </button>
                                        
                                        <button disabled={disabled} onClick={onOpenModalModification(item.id)} data-tip="Редактировать" className="btn btn-sm btn-clean btn-icon btn-icon-md"> <i className="la la-edit"></i> </button>
                                        <button
                                            disabled={disabled}
                                            onClick={onDelete(item.id)}
                                            data-tip="Удалить" 
                                            className={cn("btn btn-sm btn-clean btn-icon btn-icon-md", {
                                                'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger': isLoadingDelete
                                            })}>
                                                {!isLoadingDelete && <i className="la la-trash"></i>}
                                            </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <ReactTooltip
                    effect="solid"
                    type="dark"
                    place="top"
                />
            </div>
        );

    return (
        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
            <table className="kt-datatable__table" style={{ width: '100%' }}>
                <tbody className="kt-datatable__body ps ps--active-y">
                    {detail.modifications.map((item) => {
                        const isLoadingUpdate = loaderUpdate === item.id;
                        const isLoadingDelete = loaderDelete === item.id;
                        const disabled = isLoadingDelete && isLoadingUpdate;
                        return (
                            <tr key={item.id} className="kt-datatable__row">
                                <div style={{display: 'flex', gap: '10px', flexDirection: 'column', padding: '10px'}}>
                                    <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap'}}>
                                        <div style={{ width: '50%'}}>
                                            <span>{`ID: ${item.id}`}</span>
                                        </div>
                                        <div style={{ width: '50%'}}>
                                            <span>{`Название: ${item.title}`}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex'}}>
                                        <div style={{ width: '50%'}}><span>Цена: {getNewPrice(detail.price, item.condition, item.price, item.persent)} ₽</span></div>
                                        <div style={{ width: '50%'}}>
                                            <span>{`Старая цена: ${getNewPrice(detail.oldPrice, item.condition, item.oldPrice, item.persent)} ₽`}</span>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex'}}>
                                        <div style={{ width: '50%'}}><span>Вес/объем: {item.weight ? item.weight : detail.weight} {MEASURES[detail.measure]}</span></div>
                                        <div style={{ width: '50%'}}>
                                            <span>По умолчанию: <span style={item.default ? { color: '#0abb87' } : { color: '#fd397a' }}>{item.default ? 'Вкл.' : 'Выкл.'}</span></span>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', gap: '5px', justifyContent: 'flex-end' }}>
                                    <button
                                        disabled={disabled}
                                        style={{ marginRight: '3px' }}
                                        onClick={onToogleActive(item.id, item.active)}
                                        data-tip={item.active ? 'Выключить' : 'Включить'}
                                        className={cn("btn btn-sm btn-icon btn-icon-md", {
                                            'btn-danger': !item.active,
                                            'btn-success': item.active,
                                            'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': isLoadingUpdate
                                        })}>
                                        {!isLoadingUpdate && <i className="la la-power-off"></i>}
                                    </button>
                                    
                                    <button disabled={disabled} onClick={onOpenModalModification(item.id)} data-tip="Редактировать" className="btn btn-sm btn-clean btn-icon btn-icon-md"> <i className="la la-edit"></i> </button>
                                    <button
                                        disabled={disabled}
                                        onClick={onDelete(item.id)}
                                        data-tip="Удалить" 
                                        className={cn("btn btn-sm btn-clean btn-icon btn-icon-md", {
                                            'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger': isLoadingDelete
                                        })}>
                                            {!isLoadingDelete && <i className="la la-trash"></i>}
                                        </button>
                                    </div>
                                </div>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <ReactTooltip
                effect="solid"
                type="dark"
                place="top"
            />
        </div>
    );
}